import React, { useEffect, useState } from 'react';
import { Box, Button, Paragraph } from 'grommet';
import PageWrapper from '../../../PageWrapper/PageWrapper';
import PageTitle from '../../../PageTitle/PageTitle';
import { IProjeto } from '../../../../../Interfaces/IProjeto';
import getProjetosPorPlanta from '../../Helpers/getProjetosPorPlanta';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loading from '../../../Loading/Loading';
import ProjetosDaPlantaDataTable from './Components/ProjetosDaPlantaDataTable';
import { LinkPrevious } from 'grommet-icons';

export interface ProjetosDaPlantaProps {}

const ProjetosDaPlanta: React.FC<ProjetosDaPlantaProps> = ({}): JSX.Element => {
  const { guid } = useParams<{ guid: string }>();
  const navigate = useNavigate();

  const onVoltarClick = (): void => {
    navigate('/admin/plantas');
  };

  const [loading, setLoading] = useState<boolean>(true);
  const [projetos, setProjetos] = useState<IProjeto[]>([]);

  const getData = async (): Promise<void> => {
    setLoading(true);

    await getProjetosPorPlanta(guid!)
      .then((data): void => {
        if (data.Success) {
          setProjetos(data.Object!);
          setLoading(false);
        } else {
          toast.error('Erro ao buscar projetos da planta');
        }
      })
      .catch((): void => {
        toast.error('Erro ao buscar projetos da planta');
      });
  };

  useEffect((): void => {
    getData();
  }, []);

  return (
    <PageWrapper>
      <Box width="100%" justify="between" align="center" direction="row">
        <PageTitle Text="Projetos da Planta" />
        <Button
          primary
          label="Voltar"
          title="Voltar"
          onClick={onVoltarClick}
          icon={<LinkPrevious size="16px" />}
        />
      </Box>
      <Box alignSelf='start' align='start' width='60%'>
        <Paragraph>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vitae
          ante semper, faucibus urna quis, bibendum urna. Suspendisse consequat
          luctus dolor, ac vestibulum ex mollis egestas. Cras in sapien id diam
          facilisis hendrerit quis non orci. Duis vel sodales dui. Suspendisse
          potenti. Donec dignissim est aliquet risus fermentum consectetur.
        </Paragraph>
      </Box>
      {
        (loading) ? (<Loading />) : (
          <ProjetosDaPlantaDataTable
            projetos={projetos}
          />
        )
      }
    </PageWrapper>
  );
};

ProjetosDaPlanta.displayName = 'ProjetosDaPlanta';

export default ProjetosDaPlanta;
