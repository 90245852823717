import React, { useEffect, useState } from 'react';
import { Box, Button, Paragraph } from 'grommet';
import { Add } from 'grommet-icons';
import { toast } from 'react-toastify';
import PageWrapper from '../PageWrapper/PageWrapper';
import PageTitle from '../PageTitle/PageTitle';
import { IEmpresa } from '../../../Interfaces/IEmpresa';
import getEmpresasPrestadoras from './Helpers/getEmpresasPrestadoras';
import Loading from '../Loading/Loading';
import EmpresasPrestadorasDataTable from './Components/EmpresasPrestadorasDataTable';
import { useNavigate } from 'react-router-dom';

export interface EmpresasPrestadorasProps {}

const EmpresasPrestadoras: React.FC<EmpresasPrestadorasProps> = ({}): JSX.Element => {
  const [empresasPrestadoras, setEmpresasPrestadoras] = useState<IEmpresa[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const navigate = useNavigate();

  const onAdicionarClick = (): void => {
    navigate('/admin/adicionarEmpresaPrestadora');
  };

  const getInitialData = async () => {
    setLoading(true);

    await getEmpresasPrestadoras()
      .then((data): void => {
        if (data.Success) {
          setEmpresasPrestadoras(data.Object!);
          setLoading(false);
        } else {
          toast.error('Erro ao buscar as empresas prestadoras de serviços.');
        }
      })
      .catch((): void => {
        toast.error('Erro ao buscar as empresas prestadoras de serviços.');
      });
  };

  useEffect((): void => {
    getInitialData();
  }, []);

  return (
    <PageWrapper>
      <Box width="100%" justify="between" align="center" direction="row">
        <PageTitle Text="Empresas Prestadoras" />
        <Button
          primary
          label="Adicionar"
          title="Adicionar Empresa"
          onClick={onAdicionarClick}
          icon={<Add size="16px" />}
        />
      </Box>
      <Box alignSelf="start" align="start" width="60%">
        <Paragraph>
          Aqui você encontra as empresas prestadoras de serviços do sistema.
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vitae
          ante semper, faucibus urna quis, bibendum urna. Suspendisse consequat
          luctus dolor, ac vestibulum ex mollis egestas. Cras in sapien id diam
          facilisis hendrerit quis non orci. Duis vel sodales dui. Suspendisse
          potenti. Donec dignissim est aliquet risus fermentum consectetur.
        </Paragraph>
      </Box>
      {
        (loading) ? (<Loading />) : (
          <EmpresasPrestadorasDataTable
            empresas={empresasPrestadoras}
          />
        )
      }
    </PageWrapper>
  );
};

EmpresasPrestadoras.displayName = 'EmpresasPrestadoras';

export default EmpresasPrestadoras;
