import React, { useState } from 'react';
import { Box } from 'grommet';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import PageWrapper from '../../../PageWrapper/PageWrapper';
import PageTitle from '../../../PageTitle/PageTitle';
import EmpresaForm from '../../../Forms/Empresa/EmpresaForm';
import adicionarEmpresaInitialValues from '../../Helpers/adicionarEmpresaInitialValues';
import adicionarEmpresaSubmit from '../../Helpers/adicionarEmpresaSubmit';
import { IEmpresa } from '../../../../../Interfaces/IEmpresa';

export interface AdicionarEmpresaProps {}

const AdicionarEmpresa: React.FC<AdicionarEmpresaProps> = ({}): JSX.Element => {
  const navigate = useNavigate();
  const onCancel = (): void => navigate('/owner/manageEmpresas');

  const [submitting, setSubmitting] = useState<boolean>(false);

  const onSubmit = async (values: IEmpresa): Promise<void> => {
    setSubmitting(true);

    await adicionarEmpresaSubmit(values)
      .then((data): void => {
        if (data.Success) {
          toast.success('Empresa adicionada com sucesso');
          navigate('/owner/manageEmpresas');
        } else {
          toast.error('Erro ao adicionar empresa');
          setSubmitting(false);
        }
      })
      .catch((): void => {
        toast.error('Erro ao adicionar empresa');
        setSubmitting(false);
      });
  };

  return (
    <PageWrapper>
      <PageTitle
        Text="Adicionar Empresa"
      />
      <Box
        width="large"
      >
        <EmpresaForm
          initialValues={adicionarEmpresaInitialValues}
          onSubmit={onSubmit}
          onCancel={onCancel}
          submitting={submitting}
        />
      </Box>
    </PageWrapper>
  );
};

AdicionarEmpresa.displayName = 'AdicionarEmpresa';

export default AdicionarEmpresa;
