import React, { useState } from 'react';
import { ColumnConfig, DataTable, Text } from 'grommet';
import { IEmpresa } from '../../../../Interfaces/IEmpresa';
import { IDataTableSort } from '../../../../Interfaces/IDataTableSort';

export interface EmpresasPrestadorasDataTableProps {
  empresas: IEmpresa[];
}

const EmpresasPrestadorasDataTable: React.FC<EmpresasPrestadorasDataTableProps> = ({ empresas }): JSX.Element => {
  const [sort, setSort] = useState<IDataTableSort>({
    property: 'NomeFantasia',
    direction: 'asc',
  });

  const columns: ColumnConfig<IEmpresa>[] = [
    {
      property: 'NomeFantasia',
      primary: true,
      search: true,
      sortable: true,
      header: 'Nome Fantasia',
      render: (datum: IEmpresa): React.ReactNode => (
        <Text>{datum.NomeFantasia}</Text>
      ),
    },
  ];
  
  return (
    <DataTable
      fill="horizontal"
      data={empresas}
      border={{
        body: 'bottom',
      }}
      sortable={true}
      sort={sort}
      onSort={setSort}
      pad={{
        horizontal: 'small',
        vertical: 'small',
      }}
      rowProps={{
        true: {
          background: 'dark-brand',
        },
      }}
      columns={columns}
    />
  );
};

EmpresasPrestadorasDataTable.displayName = 'EmpresasPrestadorasDataTable';

export default EmpresasPrestadorasDataTable;
