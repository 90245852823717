import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Box } from 'grommet';
import PageWrapper from '../../../PageWrapper/PageWrapper';
import PageTitle from '../../../PageTitle/PageTitle';
import CompartilharPlanta from '../../../Forms/CompartilharPlanta/CompartilharPlanta';
import adicionarCompartilhamentoInitialValues from '../../Helpers/adicionarCompartilhamentoInitialValues';
import adicionarCompartilhamentoSubmit from '../../Helpers/adicionarCompartilhamentoSubmit';
import { IPlanta } from '../../../../../Interfaces/IPlanta';

export interface AdicionarCompartilhamentoProps {}

const AdicionarCompartilhamento: React.FC<AdicionarCompartilhamentoProps> = ({}): JSX.Element => {
  const { guid } = useParams<{ guid: string }>();
  const navigate = useNavigate();

  const onCancel = (): void => {
    navigate(`/admin/managePlantasCompartilhamentos/${guid}`);
  };

  const [submitting, setSubmitting] = useState<boolean>(false);

  const onSubmit = async (values: IPlanta): Promise<void> => {
    setSubmitting(true);

    await adicionarCompartilhamentoSubmit(values)
      .then((data): void => {
        if (data.Success) {
          toast.success('Compartilhamento adicionado com sucesso');
          onCancel();
        } else {
          toast.error('Erro ao adicionar compartilhamento');
          setSubmitting(false);
        }
      })
      .catch((): void => {
        toast.error('Erro ao adicionar compartilhamento');
        setSubmitting(false);
      });
  };

  return (
    <PageWrapper>
      <PageTitle
        Text="Adicionar Compartilhamento de planta"
      />
      <Box
        width="large"
      >
        <CompartilharPlanta
          initialValues={adicionarCompartilhamentoInitialValues(guid!)}
          onSubmit={onSubmit}
          onCancel={onCancel}
          submitting={submitting}
        />
      </Box>
    </PageWrapper>
  );
};

AdicionarCompartilhamento.displayName = 'AdicionarCompartilhamento';

export default AdicionarCompartilhamento;
