import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button } from 'grommet';
import { Add, ShareOption } from 'grommet-icons';
import { IPlanta } from '../../../../Interfaces/IPlanta';

export interface ManagePlantasTotalCompartilhamentosProps {
  planta: IPlanta;
}

const ManagePlantasTotalCompartilhamentos: React.FC<ManagePlantasTotalCompartilhamentosProps> = ({ planta }): JSX.Element => {
  const navigate = useNavigate();

  const onCompartilhamentosClick = (): void => {
    navigate(`/admin/managePlantasCompartilhamentos/${planta.Guid}`);
  };

  const onAddCompartilhamentoClick = (): void => {
    navigate(`/admin/adicionarCompartilhamento/${planta.Guid}`);
  };

  return (
    <Box
      direction="row"
      gap="1rem"
      align="center"
    >
      <Button
        size='xsmall'
        style={{ minWidth: '80px' }}
        secondary
        icon={<ShareOption size="12px" />}
        title="Clique para ver os documentos da empresa"
        onClick={onCompartilhamentosClick}
        label={planta.TotalCompartilhamentos || '0'}
      />
      <Button size='small' icon={<Add size='14px' />} hoverIndicator onClick={onAddCompartilhamentoClick} />
    </Box>
  );
};

ManagePlantasTotalCompartilhamentos.displayName = 'ManagePlantasTotalCompartilhamentos';

export default ManagePlantasTotalCompartilhamentos;
