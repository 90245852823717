import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button } from 'grommet';
import { Notes } from 'grommet-icons';
import { IProjeto } from '../../../../../../Interfaces/IProjeto';

export interface ProjetosDaPlantaActionsProps {
  projeto: IProjeto;
}

const ProjetosDaPlantaActions: React.FC<ProjetosDaPlantaActionsProps> = ({ projeto }): JSX.Element => {
  const navigate = useNavigate();

  const onProjetoConsolidadoClick = (): void => {
    navigate(`/admin/relatorioProjeto/${projeto.Guid}`);
  };

  return (
    <Box
      direction="row"
      gap="none"
      pad="none"
    >
      <Button
        icon={<Notes />}
        title='Documentos consolidados'
        onClick={onProjetoConsolidadoClick}
      />
    </Box>
  );
};

ProjetosDaPlantaActions.displayName = 'ProjetosDaPlantaActions';

export default ProjetosDaPlantaActions;
