import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Box, Button } from 'grommet';
import { Trash } from 'grommet-icons';
import plantaCompartilhamentoExcluir from '../Helpers/plantaCompartilhamentoExcluir';
import ConfirmModal from '../../ConfirmModal/ConfirmModal';
import { IEmpresa } from '../../../../Interfaces/IEmpresa';

export interface PlantasCompartilhamentosActionsProps {
  empresa: IEmpresa;
  refreshData: () => void;
}

const PlantasCompartilhamentosActions: React.FC<PlantasCompartilhamentosActionsProps> = ({ empresa, refreshData }): JSX.Element => {
  const [action, setAction] = useState<string>('');
  const [showModal, setShowModal] = useState<boolean>(false);
  const changeShowModal = (): void => {
    setShowModal(!showModal);
  };

  const confirmModalText = (): string => {
    switch (action) {
      case 'delete':
        return `Deseja excluir permanentemente o compartilhamento com a empresa ${empresa.NomeFantasia}?`;
      default:
        return '';
    }
  };

  const confirmModalTitle = (): string => {
    switch (action) {
      case 'delete':
        return 'Excluir compartilhamento?';
      default:
        return '';
    }
  };

  const onDeleteCompartilhamento = async (): Promise<void> => {
    await plantaCompartilhamentoExcluir(empresa.Guid!)
      .then((data): void => {
        if (data.Success) {
          toast.success('Equipamento excluído com sucesso');
          refreshData();
        } else {
          toast.error('Erro ao excluir o equipamento');
        }
      })
      .catch((): void => {
        toast.error('Erro ao excluir o equipamento');
      });
  };

  const onDeleteClick = (): void => {
    setAction('delete');
    changeShowModal();
  };

  const onConfirm = (): void => {
    switch (action) {
      case 'delete':
        onDeleteCompartilhamento();
        break;
      default:
        break;
    }

    changeShowModal();
  };

  return (
    <>
      <Box
        direction="row"
        gap="1rem"
        pad="none"
      >
        <Button
          icon={<Trash size='16px' color="status-critical" />}
          onClick={onDeleteClick}
          title="Excluir compartilhamento"
        />
      </Box>
      {
        (showModal) && (
          <ConfirmModal
            title={confirmModalTitle()}
            text={confirmModalText()}
            onCancel={changeShowModal}
            onConfirm={onConfirm}
          />
        )
      }
    </>
  );
};

PlantasCompartilhamentosActions.displayName = 'PlantasCompartilhamentosActions';

export default PlantasCompartilhamentosActions;
