import React, { useState } from 'react';
import { Box, Button, FormExtendedEvent } from 'grommet';
import { IPlanta } from '../../../../Interfaces/IPlanta';
import FormWrapper from '../../FormWrapper/FormWrapper';
import DropDownEmpresasFornecedoras from '../../DropDownEmpresasFornecedoras/DropDownEmpresasFornecedoras';

export interface CompartilharPlantaProps {
  initialValues: IPlanta;
  onSubmit: (values: IPlanta) => Promise<void>;
  submitting: boolean;
  onCancel?: () => void;
}

const CompartilharPlanta: React.FC<CompartilharPlantaProps> = ({
  initialValues, onSubmit, submitting, onCancel,
}): JSX.Element => {
  const [values, setValues] = useState<IPlanta>(initialValues);
  const onChange = (nextValues: IPlanta): void => setValues(nextValues);

  const onClear = (): void => {
    if (onCancel) {
      onCancel();
    } else {
      setValues(initialValues);
    }
  };

  const handleSubmit = async (evt: FormExtendedEvent<IPlanta>): Promise<void> => {
    const { value } = evt;

    await onSubmit(value);
  };

  return (
    <FormWrapper
      values={values}
      onChange={onChange}
      onSubmit={handleSubmit}
    >
      <DropDownEmpresasFornecedoras
        name="EmpresaGuid"
        label="Fornecedores"
        title="Escolha as empresas para compartilhar"
        disabled={submitting}
      />

      <Box
        direction="row"
        gap="medium"
        justify="start"
        margin={{
          top: 'medium',
          bottom: 'medium',
        }}
      >
        <Button
          type="submit"
          label="Salvar"
          title="Salvar"
          disabled={submitting}
          primary
          reverse
        />
        <Button
          label="Cancelar"
          title="Cancelar"
          onClick={onClear}
          reverse
        />
      </Box>
    </FormWrapper>
  );
};

CompartilharPlanta.displayName = 'CompartilharPlanta';

export default CompartilharPlanta;
