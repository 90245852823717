import { BasicReturn, IBasicReturn } from '../../../../../Interfaces/IBasicReturn';
import { IUser } from '../../../../../Interfaces/IUser';
import MyAxiosInstance from '../../../../../utils/AxiosInstance';

const getAcessosDoUsuario = async (usuarioGuid: string): Promise<IBasicReturn<IUser>> => {
  let apiReturn: IBasicReturn<IUser> = new BasicReturn(true);

  await MyAxiosInstance().get < IBasicReturn<IUser>>(`Empresa/PlantasPorUsuarioGuid/${usuarioGuid}`)
    .then((response): IBasicReturn<IUser> => {
      const { data } = response;

      apiReturn = data;

      return apiReturn;
    })
    .catch((): IBasicReturn => {
      apiReturn = new BasicReturn(false);

      return apiReturn;
    });

  return apiReturn;
};

export default getAcessosDoUsuario;
