import { Box } from 'grommet';
import { Group, Organization } from 'grommet-icons';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { SideBarCustomizedButton } from '../SideBarNavStyles';

export interface SidebarLinksOwnerProps {}

const SidebarLinksOwner: React.FC<SidebarLinksOwnerProps> = ({}): JSX.Element => {
  const navigate = useNavigate();
  const location = useLocation();

  const checkifRouteIsActive = (route: string): string => {
    if (location.pathname === route) {
      return 'active';
    }
    return '';
  };

  // /* Administração */
  const onManageUsersClick = (): void => {
    navigate('/owner/manageUsers');
  };

  const onEmpresasClick = (): void => {
    navigate('/owner/manageEmpresas');
  };
  // /* /Administração */

  return (
    <Box
      gap="6px"
      margin={{
        bottom: '32px',
      }}
      width="100%"
    >
      <SideBarCustomizedButton
        primary
        label="Gerenciar Usuários"
        icon={<Group size="14px" />}
        onClick={onManageUsersClick}
        className={checkifRouteIsActive('/owner/manageUsers')}
      />
      <SideBarCustomizedButton
        primary
        label="Gerenciar Empresas"
        icon={<Organization size="14px" />}
        onClick={onEmpresasClick}
        className={checkifRouteIsActive('/owner/manageEmpresas')}
      />
    </Box>
  );
};

SidebarLinksOwner.displayName = 'SidebarLinksOwner';

export default SidebarLinksOwner;
