import {
  Box, Button, CheckBox, FormExtendedEvent, FormField, TextInput,
} from 'grommet';
import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { IUser } from '../../../../Interfaces/IUser';
import FormWrapper from '../../FormWrapper/FormWrapper';
import { onEmailValidationRegExp } from '../../../../utils/onEmailRegexValidation';
import { IBasicReturn } from '../../../../Interfaces/IBasicReturn';
import useAppContext from '../../Template/Context/useAppContext';

export interface LoginFormProps {
  initialValues: IUser;
  onSubmit: (values: IUser) => Promise<IBasicReturn<IUser>>;
}

const LoginForm: React.FC<LoginFormProps> = ({ initialValues, onSubmit }): JSX.Element => {
  const navigate = useNavigate();

  const {
    state: { IsAuthenticated },
    dispatch,
  } = useAppContext();

  const [submitting, setSubmitting] = React.useState<boolean>(false);
  const [values, setValues] = React.useState<IUser>(initialValues);
  const onChange = (nextValues: IUser): void => setValues(nextValues);

  const onCheckIfItsRedirectable = (): void => {
    const urlSearchParams = new URLSearchParams(window.location.search);

    const params = Object.fromEntries(urlSearchParams.entries());

    if (params.RedirectUrl) {
      navigate(params.RedirectUrl || '/');
    } else {
      navigate('/auth/minhaEmpresa');
    }
  };

  const handleSubmit = async (evt: FormExtendedEvent<IUser>): Promise<void> => {
    const { value } = evt;
    setSubmitting(true);

    await onSubmit(value)
      .then((data): void => {
        if (data.Success) {
          const { Object: user } = data;
          toast.success('Login efetuado com sucesso');

          dispatch({
            type: 'changeAuth',
            value: true,
          });

          dispatch({
            type: 'changeDrawer',
            value: true,
          });

          dispatch({
            type: 'changeFirstName',
            value: user?.FirstName!,
          });

          dispatch({
            type: 'changeLastName',
            value: user?.LastName!,
          });

          dispatch({
            type: 'changeEmail',
            value: user?.Email!,
          });

          dispatch({
            type: 'changeOwner',
            value: user?.Owner || false,
          });

          dispatch({
            type: 'changeAdmin',
            value: user?.Admin || false,
          });

          dispatch({
            type: 'changeTechnician',
            value: user?.Technician || false,
          });

          dispatch({
            type: 'changeValidated',
            value: user?.Validated || false,
          });

          if (user?.Empresa) {
            dispatch({
              type: 'changeEmpresa',
              value: user?.Empresa,
            });
          }

          // if (user?.Empresa && user?.Empresa?.Guid) {
          //   dispatch({
          //     type: 'changeEmpresaGuid',
          //     value: user?.Empresa?.Guid,
          //   });
          // }

          // if (user?.Empresa && user?.Empresa?.Tipo) {
          //   dispatch({
          //     type: 'changeEmpresaTipo',
          //     value: user?.Empresa?.Tipo,
          //   });
          // }

          onCheckIfItsRedirectable();
        } else {
          toast.error('Erro ao efetuar login. Provavelmente a senha está errada');
        }
        setSubmitting(false);
      })
      .catch((): void => {
        setSubmitting(false);
        toast.error('Erro ao efetuar login');
      });
  };

  const validatePassword = (value: string): string | undefined => {
    if (value.length < 6) {
      return 'A senha deve ter no mínimo 6 caracteres';
    }
  };

  useEffect(() => {
    if (IsAuthenticated) {
      navigate('/auth/minhaEmpresa');
    }
  }, []);

  return (
    <FormWrapper
      values={values}
      onChange={onChange}
      onSubmit={handleSubmit}
    >
      <FormField
        label="Email"
        name="Email"
        width="100%"
        validate={onEmailValidationRegExp('Email inválido')}
        required
      >
        <TextInput
          name="Email"
          type="email"
          aria-label="Por favor informe seu email"
          title="Por favor informe seu email"
          placeholder="mail@mail.com"
        />
      </FormField>

      <FormField
        label="Password"
        name="Password"
        width="100%"
        validate={validatePassword}
        required
      >
        <TextInput
          name="Password"
          type="password"
          aria-label="Por favor informe a senha"
          title="Por favor informe a senha"
          placeholder="######"
        />
      </FormField>

      <CheckBox
        name="KeepConnected"
        label="Mantenha-me conectado"
      />

      <Box
        direction="row"
        gap="small"
        justify="end"
        margin={{
          top: 'medium',
          bottom: 'medium',
        }}
      >
        <Button
          type="submit"
          label="Entrar"
          title="Efetuar login"
          disabled={submitting}
          primary
          reverse
        />
      </Box>
    </FormWrapper>
  );
};

LoginForm.displayName = 'LoginForm';

export default LoginForm;
