import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { FormField, SelectMultiple, Spinner } from 'grommet';
import { IEmpresa } from '../../../Interfaces/IEmpresa';
import getDropDownEmpresasFornecedoras from './Helpers/getDropDownEmpresasFornecedoras';

export interface DropDownEmpresasFornecedorasProps {
  name: string;
  label: string;
  title: string;
  disabled?: boolean;
}

const DropDownEmpresasFornecedoras: React.FC<DropDownEmpresasFornecedorasProps> = ({
  name, label, title, disabled,
}): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(true);
  const [empresas, setEmpresas] = useState<IEmpresa[]>([]);

  const getInitialData = async (): Promise<void> => {
    setLoading(true);

    await getDropDownEmpresasFornecedoras()
      .then((data): void => {
        if (data.Success) {
          setEmpresas(data.Object!);
        } else {
          toast.error('Erro ao buscar empresas');
        }
        setLoading(false);
      })
      .catch((): void => {
        toast.error('Erro ao buscar empresas');
      });
  };

  useEffect((): void => {
    getInitialData();
  }, []);
  
  return (loading) ? (<Spinner />) : (
    <FormField
      label={label}
      name={name}
      width="100%"
      required
    >
      <SelectMultiple
        name="EmpresaGuid"
        aria-label={title}
        title={title}
        placeholder={label}
        options={empresas}
        valueKey={{ key: 'Guid', reduce: true }}
        labelKey="NomeFantasia"
        disabled={disabled}
        multiple
        searchPlaceholder="Pesquisar"
        messages={{
          selected: '{selected} SELECIONADOS',
          clearAll: 'Limpar seleção',
          selectAll: 'Selecionar todos',
          summarizedValue: '{selected} de {total} selecionados',
          selectedOfTotal: '{selected} de {total} selecionados',
          showMore: 'Mostrar mais',
        }}
        showSelectedInline
      />
    </FormField>
  );
};

DropDownEmpresasFornecedoras.displayName = 'DropDownEmpresasFornecedoras';

export default DropDownEmpresasFornecedoras;
