import React from 'react';
import useAppContext from '../Template/Context/useAppContext';
import { Box, Text, Tip } from 'grommet';

export interface AdminCheckerProps {
  children: JSX.Element;
  label?: string;
  tecnico?: boolean;
}

const AdminChecker: React.FC<AdminCheckerProps> = ({
  children, label = 'Acesso restrito a administradores do sistema', tecnico = false,
}): JSX.Element => {
  const {
    state: { Admin, Technician },
  } = useAppContext();

  if (Admin) return children;

  if (tecnico && Technician) return children;

  const childrenWithExtraProp = React.cloneElement(children, { disabled: true });

  return (
    <Tip
      plain
      content={(
        <Box pad="small" width="small" background="background-back">
          <Text size="xsmall">{label}</Text>
        </Box>
      )}>
      <Box pad="none">
        {childrenWithExtraProp}
      </Box>
    </Tip>
  );
};

AdminChecker.displayName = 'AdminChecker';

export default AdminChecker;
