import React from 'react';
import { IPlanta } from '../../../../Interfaces/IPlanta';
import { Box, List, Text } from 'grommet';

export interface PlantasCompartilhadasListaProps {
  plantas: IPlanta[];
}

const PlantasCompartilhadasLista: React.FC<PlantasCompartilhadasListaProps> = ({ plantas }): JSX.Element => {
  return (
    <Box
      align="center"
      pad="none"
    >
      <List
        data={plantas}
        primaryKey={(item) => (
          <Text key={item.Nome} weight="bold">
            {item.Nome}
          </Text>
        )}
        itemKey={(item) => item.Nome!}
      />
    </Box>
  );
};

PlantasCompartilhadasLista.displayName = 'PlantasCompartilhadasLista';

export default PlantasCompartilhadasLista;
