import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button } from 'grommet';
import { Workshop } from 'grommet-icons';
import { IPlanta } from '../../../../Interfaces/IPlanta';

export interface ManagePlantasTotalProjetosProps {
  planta: IPlanta;
}

const ManagePlantasTotalProjetos: React.FC<ManagePlantasTotalProjetosProps> = ({ planta }): JSX.Element => {
  const navigate = useNavigate();
  
  const onProjetosClick = (): void => {
    navigate(`/admin/managePlantasProjetos/${planta.Guid}`);
  };

  return (
    <Box
      direction="row"
      gap="1rem"
      align="center"
    >
      <Button
        size='xsmall'
        style={{ minWidth: '80px' }}
        secondary
        icon={<Workshop size="12px" />}
        title="Clique para ver os documentos da empresa"
        onClick={onProjetosClick}
        label={planta.TotalProjetos || '0'}
      />
    </Box>
  );
};

ManagePlantasTotalProjetos.displayName = 'ManagePlantasTotalProjetos';

export default ManagePlantasTotalProjetos;
