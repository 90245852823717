import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Box, Button, Paragraph } from 'grommet';
import { Add, LinkPrevious } from 'grommet-icons';
import PageWrapper from '../PageWrapper/PageWrapper';
import PageTitle from '../PageTitle/PageTitle';
import { IProjetoDocumento } from '../../../Interfaces/IProjetoDocumento';
import getDocumentosDoProjeto from './Helpers/getDocumentosDoProjeto';
import Loading from '../Loading/Loading';
import ProjetoRelatorioDocumentosTable from '../ManageProjetosRelatorio/Components/ProjetoRelatorioDocumentosTable';

export interface ManageProjetosDocumentosProps {}

const ManageProjetosDocumentos: React.FC<ManageProjetosDocumentosProps> = ({}): JSX.Element => {
  const navigate = useNavigate();
  const { guid } = useParams<{ guid: string }>();

  const onAddDocumentoClick = (): void => {
    navigate(`/auth/adicionarDocumentoProjeto/${guid}`);
  };

  const onBackToProjetos = (): void => {
    navigate('/auth/projetos');
  };

  const [documentos, setDocumentos] = useState<IProjetoDocumento[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const getData = async (): Promise<void> => {
    setLoading(true);

    await getDocumentosDoProjeto(guid!)
      .then((data): void => {
        if (data.Success) {
          setDocumentos(data.Object!);
        } else {
          toast.error('Erro ao buscar documentos da empresa');
        }

        setLoading(false);
      })
      .catch((): void => {
        toast.error('Erro ao buscar documentos da empresas');
      });
  };

  useEffect((): void => {
    getData();
  }, []);

  return (
    <PageWrapper>
       <Box width="100%" justify="between" align="center" direction="row">
        <PageTitle Text="Documentos do Projeto" />
        <Button
          primary
          label="Adicionar"
          title="Adicionar Documento"
          onClick={onAddDocumentoClick}
          icon={<Add size="16px" />}
        />
      </Box>
      <Box alignSelf="start" align="start" width="60%">
        <Paragraph>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vitae
          ante semper, faucibus urna quis, bibendum urna. Suspendisse consequat
          luctus dolor, ac vestibulum ex mollis egestas. Cras in sapien id diam
          facilisis hendrerit quis non orci. Duis vel sodales dui. Suspendisse
          potenti. Donec dignissim est aliquet risus fermentum consectetur. 
        </Paragraph>
      </Box>
      {
        (loading) ? (<Loading />) : (
          <Box
            fill="horizontal"
            direction="column"
            gap="none"
            margin={{
              top: 'medium',
              bottom: 'medium',
            }}
          >
            <ProjetoRelatorioDocumentosTable
              refreshData={getData}
              documentos={documentos}
              completo
            />
          </Box>
        )
      }
      <Box
        width="100%"
        align="center"
        direction="row"
        gap="xsmall"
        justify="start"
      >
        <Button
          primary
          label="Voltar"
          title="Voltar"
          onClick={onBackToProjetos}
          icon={<LinkPrevious  size="16px"/>}
        />
      </Box>
    </PageWrapper>
  );
};

ManageProjetosDocumentos.displayName = 'ManageProjetosDocumentos';

export default ManageProjetosDocumentos;
