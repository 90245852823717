import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box } from 'grommet';
import PageWrapper from '../../PageWrapper/PageWrapper';
import PageTitle from '../../PageTitle/PageTitle';
import onChangePasswordInitialValues from './Helpers/onchangePasswordInitialValues';
import AlterarSenhaForm from '../../Forms/AlterarSenha/AlterarSenhaForm';
import onChangePasswordSubmit from './Helpers/onChangePasswordSubmit';

export interface ChangePasswordProps {}

const ChangePassword: React.FC<ChangePasswordProps> = (): JSX.Element => {
  const navigate = useNavigate();
  const onCancel = (): void => {
    navigate('/auth/minhaEmpresa');
  };

  return (
    <PageWrapper>
      <PageTitle
        Text="Alterar Senha"
      />
      <Box
        width="medium"
      >
        <AlterarSenhaForm
          initialValues={onChangePasswordInitialValues}
          onSubmit={onChangePasswordSubmit}
          onCancel={onCancel}
        />
      </Box>
    </PageWrapper>
  );
};

ChangePassword.displayName = 'ChangePassword';

export default ChangePassword;
