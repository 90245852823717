import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Anchor, Box, Button, Text } from 'grommet';
import { Add } from 'grommet-icons';
import { IEmpresa } from '../../../../Interfaces/IEmpresa';
import useAppContext from '../../Template/Context/useAppContext';

export interface ManageEmpresasTotalUsuariosProps {
  empresa: IEmpresa;
}

const ManageEmpresasTotalUsuarios: React.FC<ManageEmpresasTotalUsuariosProps> = ({ empresa }): JSX.Element => {
  const navigate = useNavigate();

  const {
    state: { Owner },
  } = useAppContext();

  const onUsuariosClick = (): void => {
    navigate(`/owner/manageEmpresasUsuarios/${empresa.Guid}`);
  };

  const onNavigateToAddUsuario = (): void => {
    navigate(`/owner/adicionarEmpresasUsuario/${empresa.Guid}`);
  };

  return (Owner) ? (
    <Text>{empresa.TotalUsuarios || 0}</Text>
  ) : (
    <Box
      direction="row"
      gap="xmall"
      align="center"
    >
      <Anchor
        title="Clique para ver os usuários da empresa"
        onClick={onUsuariosClick}
      >
        {empresa.TotalUsuarios || 0}
      </Anchor>
      <Button
        title="Adicionar documento"
        icon={<Add size="16" />}
        onClick={onNavigateToAddUsuario}
      />
    </Box>
  );
};

ManageEmpresasTotalUsuarios.displayName = 'ManageEmpresasTotalUsuarios';

export default ManageEmpresasTotalUsuarios;
