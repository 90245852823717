import {
  Box, ResponsiveContext, Image, Select, BasicSelectProps, Text,
} from 'grommet';
import { FormDown, Sun } from 'grommet-icons';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import useAppContext from '../../../Context/useAppContext';
import { changeLanguage } from 'i18next';
import InterdevSwitch from '../../../../InterdevSwitch/InterdevSwitch';
import ProfileCard from '../ProfileCard/ProfileCard';
import SidebarNavWithStyles from './Styles/SidebarNavWithStyles';
import SidebarLinksFornecedor from './Components/SidebarLinksFornecedor';
import SidebarLinksTomador from './Components/SidebarLinksTomador';
import SidebarLinksOwner from './Components/SidebarLinksOwner';

export interface SidebarNavProps {}

const SidebarNav: React.FC<SidebarNavProps> = ({}): JSX.Element => {
  const size = useContext(ResponsiveContext);

  const {
    state: { Theme, Language, Empresa, Owner },
    dispatch,
  } = useAppContext();

  const navigate = useNavigate();

  // const onTestingErrorClick = (): void => {
  //   navigate('/owner/testingError');
  // };

  // const onImportCsvClick = (): void => {
  //   navigate('/auth/importCsv');
  // };

  // /* Administração */
  const onMinhaEmpresaClick = (): void => {
    navigate('/auth/minhaEmpresa');
  };
  // /* /Administração */

  const languageOptions: BasicSelectProps['options'] = [
    // { label: <LanguageDropDownItem countryCode="BR" text="Português - BR" />, value: 'pt' },
    // { label: <LanguageDropDownItem countryCode="US" text="English - US" />, value: 'en' },
    { label: 'Português - BR', value: 'pt' },
    { label: 'English - US', value: 'eng' },
  ];

  const changeTheme = (): void => {
    dispatch({
      type: 'changeTheme',
      value: Theme === 'light' ? 'dark' : 'light',
    });
  };
  /* /Administração */

  const showValueLabel = (option: string): JSX.Element => {
    // @ts-expect-error This is something broken in Grommet types
    const valueByLabel: string = languageOptions.filter(opt => opt.value === option)[0].label;

    return (
      <Box
        pad="xsmall"
      >
        <Text size="small" color="#DBDEFD">{valueByLabel}</Text>
      </Box>
    );
  };

  return (
    <SidebarNavWithStyles
      fill={size === 'small' ? 'horizontal' : undefined}
      pad="medium"
      direction="column"
      elevation="large"
      justify="between"
      background="linear-gradient(352deg, rgba(41,54,129,1) 3%, rgba(17,32,55,1) 22%)"
    >
      <Box
        align="center"
        justify="center"
        direction="column"
        pad="none"
      >
        <Image
          src="/sinapseflor_sidebar.png"
          alt="Sinapse Flow"
          style={{
            maxWidth: '30%',
            cursor: 'pointer',
          }}
          onClick={onMinhaEmpresaClick}
        />
        <Select
          options={languageOptions}
          value={Language}
          valueKey={{ key: 'value', reduce: true }}
          labelKey="label"
          valueLabel={showValueLabel}
          onChange={changeLanguage}
          placeholder="Language"
          size="small"
          style={{
            maxWidth: '120px',
            fontSize: '14px',
            padding: '8px',
          }}
          margin={{ top: '24px', bottom: '48px' }}
          icon={<FormDown size="16px" color="#DBDEFD" />}
        />
        {
          (!Owner && Empresa && Empresa.Tipo && Empresa.Tipo === 1) && (
            <SidebarLinksFornecedor />
          )
        }

        {
          (!Owner && Empresa && Empresa.Tipo && Empresa.Tipo === 2) && (
            <SidebarLinksTomador />
          )
        }

        {
          (Owner) && (
            <SidebarLinksOwner />
          )
        }

        <InterdevSwitch
          activeLabel=""
          inactiveLabel=""
          value={Theme === 'light' ? false : true}
          onChange={changeTheme}
          name="Active"
          title="Alterar o Status da empresa"
          text={<Sun color="brand" />}
          width="auto"
          direction="row"
        />
      </Box>

      <ProfileCard />
    </SidebarNavWithStyles>
  );
};

SidebarNav.displayName = 'SidebarNav';

export default SidebarNav;
