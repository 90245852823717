import { Box, Button, Paragraph } from 'grommet';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Add } from 'grommet-icons';
import { useNavigate } from 'react-router-dom';
import PageWrapper from '../../PageWrapper/PageWrapper';
import PageTitle from '../../PageTitle/PageTitle';
import { IPlanta } from '../../../../Interfaces/IPlanta';
import getPlantasPorUsuario from './Helpers/getPlantasPorUsuario';
import Loading from '../../Loading/Loading';
import ManagePlantasTable from '../../ManagePlantas/Components/ManagePlantasTable';

export interface MinhasPlantasProps {}

const MinhasPlantas: React.FC<MinhasPlantasProps> = ({}): JSX.Element => {
  const navigate = useNavigate();

  const onAddPlantaClik = (): void => {
    navigate('/admin/adicionarPlanta');
  };

  const [plantas, setPlantas] = useState<IPlanta[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const getData = async (): Promise<void> => {
    setLoading(true);

    await getPlantasPorUsuario()
      .then((data): void => {
        if (data.Success) {
          setPlantas(data.Object!);
        } else {
          toast.error('Erro ao buscar plantas');
        }

        setLoading(false);
      })
      .catch((): void => {
        toast.error('Erro ao buscar plantas');
      });
  };

  useEffect((): void => {
    getData();
  }, []);

  return (
    <PageWrapper>
      <Box width='100%' justify='between' align='center' direction='row'>
        <PageTitle Text='Minhas Plantas' />
        <Button
          primary
          label='Adicionar'
          title='Adicionar Planta'
          onClick={onAddPlantaClik}
          icon={<Add size='16px' />}
        />
      </Box>
      <Box alignSelf='start' align='start' width='60%'>
        <Paragraph>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vitae
          ante semper, faucibus urna quis, bibendum urna. Suspendisse consequat
          luctus dolor, ac vestibulum ex mollis egestas. Cras in sapien id diam
          facilisis hendrerit quis non orci. Duis vel sodales dui. Suspendisse
          potenti. Donec dignissim est aliquet risus fermentum consectetur.
        </Paragraph>
      </Box>
      {loading ? (
        <Loading />
      ) : (
        <Box
          fill='horizontal'
          direction='column'
          gap='none'
          margin={{
            top: 'medium',
          }}
        >
          {/* <PesquisarPlantas
              refreshData={getData}
              setResults={setData}
              empresaGuid={guid!}
            /> */}
          <ManagePlantasTable plantas={plantas || []} refreshData={getData} />
        </Box>
      )}
    </PageWrapper>
  );
};

MinhasPlantas.displayName = 'MinhasPlantas';

export default MinhasPlantas;
