import { ResponsiveContext, SelectMultiple } from 'grommet';
import React, { ReactNode, useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { IPlanta } from '../../../Interfaces/IPlanta';
import getDropDownPlantas from '../DropDownPlantas/Helpers/getDropDownPlantas';
import Loading from '../Loading/Loading';
import DropDownPlantasOption from './Components/DropDownPlantasOption';

export interface DropDownPlantasMultipleProps {
  label: string;
  title: string;
  empresaGuid: string;
  disabled?: boolean;
}

const DropDownPlantasMultiple: React.FC<DropDownPlantasMultipleProps> = ({
  label, title, disabled, empresaGuid,
}): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(true);
  const [plantas, setPlantas] = useState<IPlanta[]>([]);



  const size = useContext(ResponsiveContext);

  const getInitialData = async (): Promise<void> => {
    setLoading(true);

    if (empresaGuid === '') {
      setLoading(false);
      return;
    }

    await getDropDownPlantas(empresaGuid)
      .then((data): void => {
        if (data.Success) {
          setPlantas(data.Object!);
        } else {
          toast.error('Erro ao buscar plantas');
        }
        setLoading(false);
      })
      .catch((): void => {
        toast.error('Erro ao buscar plantas');
      });
  };

  useEffect((): void => {
    getInitialData();
  }, []);

  return (loading) ? (<Loading />) : (
    <SelectMultiple
      name="PlantaGuid"
      aria-label={title}
      title={title}
      placeholder={label}
      options={plantas}
      valueKey={{ key: 'Guid', reduce: true }}
      labelKey={(option: IPlanta): ReactNode => <DropDownPlantasOption planta={option} />}
      disabled={disabled}
      width={(size === 'small') ? '100%' : '50%'}
      multiple
      searchPlaceholder="Pesquisar"
      messages={{
        selected: '{selected} SELECIONADOS',
        clearAll: 'Limpar seleção',
        selectAll: 'Selecionar todos',
        summarizedValue: '{selected} de {total} selecionados',
        selectedOfTotal: '{selected} de {total} selecionados',
        showMore: 'Mostrar mais',
      }}
      showSelectedInline
    />
  );
};

DropDownPlantasMultiple.displayName = 'DropDownPlantasMultiple';

export default DropDownPlantasMultiple;
