import React from 'react';
import PageWrapper from '../PageWrapper/PageWrapper';
import PageTitle from '../PageTitle/PageTitle';
import RecuperarSenhaForm from '../Forms/RecuperarSenha/RecuperarSenhaForm';
import passwordRecoveryInitialValues from './Helpers/passwordRecoveryInitialValues';
import { Box } from 'grommet';
import passwordRecoverySendCode from './Helpers/passwordRecoverySendCode';
import passwordRecoverySubmit from './Helpers/passwordRecoverySubmit';

export interface PasswordRecoveryProps {}

const PrimeiroAcesso: React.FC<PasswordRecoveryProps> = ({}): JSX.Element => {
  return (
    <PageWrapper>
      <PageTitle
        Text="Gerar primeiro acesso"
      />
      <Box
        width="medium"
      >
        <RecuperarSenhaForm
          initialValues={passwordRecoveryInitialValues}
          sendCode={passwordRecoverySendCode}
          onSubmit={passwordRecoverySubmit}
        />
      </Box>
    </PageWrapper>
  );
};

PrimeiroAcesso.displayName = 'PrimeiroAcesso';

export default PrimeiroAcesso;
