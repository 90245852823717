import React, { useContext } from 'react';
import { Box, Button, FormExtendedEvent, ResponsiveContext } from 'grommet';
import { IUser } from '../../../../Interfaces/IUser';
import FormWrapper from '../../FormWrapper/FormWrapper';
import InterdevSwitch from '../../InterdevSwitch/InterdevSwitch';
import DropDownPlantasMultiple from '../../DropDownPlantasMultiple/DropDownPlantasMultiple';

export interface PlantasAcessoFormProps {
  initialValues: IUser;
  onSubmit: (values: IUser) => Promise<void>;
  submitting: boolean;
  empresaGuid: string;
  onCancel?: () => void;
}

const PlantasAcessoForm: React.FC<PlantasAcessoFormProps> = ({
  initialValues, onSubmit, onCancel, empresaGuid, submitting,
}): JSX.Element => {
  const [values, setValues] = React.useState<IUser>(initialValues);
  const onChange = (nextValues: IUser): void => setValues(nextValues);

  const onClear = (): void => {
    if (onCancel) {
      onCancel();
    } else {
      setValues(initialValues);
    }
  };

  const handleSubmit = async (evt: FormExtendedEvent<IUser>): Promise<void> => {
    onSubmit(evt.value);
  };

  const onTodasChange = (value: boolean): void => {
    setValues({ ...values, TodasPlantas: value });
  };

  const size = useContext(ResponsiveContext);
  return (
    <Box
      width="large"
    >
      <FormWrapper
        values={values}
        onChange={onChange}
        onSubmit={handleSubmit}
      >
        <Box
          direction={(size === 'small') ? 'column' : 'row'}
          gap="small"
          justify="end"
        >
          <InterdevSwitch
            activeLabel="Todas as plantas"
            inactiveLabel="Escolha ao lado"
            value={values.TodasPlantas || false}
            onChange={onTodasChange}
            name="TodasPlantas"
            title="Alterar tipo de acesso"
            text="Tipo de Acesso"
          />

          {
            (!values.TodasPlantas) && (
              <DropDownPlantasMultiple
                label="Planta"
                title="Por favor selecione a planta"
                empresaGuid={empresaGuid}
                disabled={submitting}
              />
            )
          }
        </Box>
        <Box
          direction="row"
          gap="medium"
          justify="start"
          margin={{
            top: 'medium',
            bottom: 'medium',
          }}
        >
          <Button
            type="submit"
            label="Salvar"
            title="Salvar"
            disabled={submitting}
            primary
            reverse
          />
          <Button
            label="Cancelar"
            title="Cancelar"
            onClick={onClear}
            reverse
          />
        </Box>
      </FormWrapper>
    </Box>
  );
};

PlantasAcessoForm.displayName = 'PlantasAcessoForm';

export default PlantasAcessoForm;
