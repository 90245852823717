import { IEmpresa } from '../../../../Interfaces/IEmpresa';

const adicionarEmpresaPrestadoraInitialValues = (): IEmpresa => ({
  Tipo: 1,
  CNPJ: '',
  RazaoSocial: '',
  NomeFantasia: '',
  Endereco: '',
  ResponsavelTecnico: '',
  Telefone: '',
  Email: '',
  Ativo: true,
});

export default adicionarEmpresaPrestadoraInitialValues;
