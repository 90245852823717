import React, { useState } from 'react';
import { ColumnConfig, DataTable, Text } from 'grommet';
import { IEmpresa } from '../../../../Interfaces/IEmpresa';
import { IDataTableSort } from '../../../../Interfaces/IDataTableSort';
import CriadoPor from '../../CriadoPor/CriadoPor';
import PlantasCompartilhamentosActions from './PlantasCompartilhamentosActions';

export interface PlantasCompartilhamentosDataTableProps {
  empresas: IEmpresa[];
  refreshData: () => void;
}

const PlantasCompartilhamentosDataTable: React.FC<PlantasCompartilhamentosDataTableProps> = ({ empresas, refreshData }): JSX.Element => {
  const [sort, setSort] = useState<IDataTableSort>({
    property: 'NomeFantasia',
    direction: 'asc',
  });

  const columns: ColumnConfig<IEmpresa>[] = [
    {
      property: 'NomeFantasia',
      primary: false,
      search: true,
      sortable: true,
      header: 'Nome Fantasia',
      render: (datum: IEmpresa): React.ReactNode => (
        <Text>{datum.NomeFantasia}</Text>
      ),
    },
    {
      property: 'CriadoPor',
      primary: false,
      search: false,
      sortable: true,
      header: 'Adicionado por',
      render: (datum: IEmpresa): React.ReactNode => (
        <CriadoPor
          criadoPor={datum.CriadoPor!}
        />
      ),
    },
    {
      property: 'Guid',
      primary: false,
      search: false,
      sortable: false,
      header: 'Ações',
      render: (datum: IEmpresa): React.ReactNode => (
        <PlantasCompartilhamentosActions
          empresa={datum}
          refreshData={refreshData}
        />
      ),
    },
  ];

  return (
    <DataTable
      fill="horizontal"
      data={empresas}
      background={{
        body: ['background', 'background-front'],
      }}
      border={{
        body: 'bottom',
      }}
      sortable={false}
      sort={sort}
      onSort={setSort}
      pad={{
        horizontal: 'small',
        vertical: 'xsmall',
      }}
      rowProps={{
        true: {
          background: 'dark-brand',
        },
      }}
      columns={columns}
    />
  );
};

PlantasCompartilhamentosDataTable.displayName = 'PlantasCompartilhamentosDataTable';

export default PlantasCompartilhamentosDataTable;
