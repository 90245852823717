import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import PageWrapper from '../../PageWrapper/PageWrapper';
import PageTitle from '../../PageTitle/PageTitle';
import Loading from '../../Loading/Loading';
import getAcessosDoUsuario from './Helpers/getAcessosDoUsuario';
import { IUser } from '../../../../Interfaces/IUser';
import PlantasAcessoForm from '../../Forms/PlantasAcesso/PlantasAcessoForm';
import useAppContext from '../../Template/Context/useAppContext';
import acessosDoUsuarioSubmit from './Helpers/acessosDoUsuarioSubmit';

export interface PlantasAcessosProps {}

const PlantasAcessos: React.FC<PlantasAcessosProps> = ({}): JSX.Element => {
  const navigate = useNavigate();
  const { guid } = useParams<{ guid: string }>();

  const {
    state: { Empresa },
  } = useAppContext();

  const onCancel = (): void => {
    navigate('/admin/usuarios');
  };

  const [loading, setLoading] = useState<boolean>(true);
  const [plantas, setPlantas] = useState<IUser>({});

  const getInitialData = async (): Promise<void> => {
    setLoading(true);

    await getAcessosDoUsuario(guid!)
      .then((data): void => {
        if (data.Success) {
          const { Object } = data;

          const plantaGuid: string[] = [];

          for (const planta of Object!.Plantas!) {
            plantaGuid.push(planta.Guid!);
          }

          const usuarios: IUser = {
            ...data.Object,
            Guid: guid,
            PlantaGuid: plantaGuid,
          };

          setPlantas(usuarios);
        } else {
          toast.error('Erro ao buscar plantas');
        }
        setLoading(false);
      })
      .catch((): void => {
        toast.error('Erro ao buscar plantas');
      });
  };

  const onSubmit = async (values: IUser): Promise<void> => {
    setLoading(true);

    await acessosDoUsuarioSubmit(values)
      .then((data): void => {
        if (data.Success) {
          toast.success('Plantas salvas com sucesso');
          navigate('/admin/usuarios');
        } else {
          toast.error('Erro ao salvar plantas');
          setLoading(false);
        }
      })
      .catch((): void => {
        toast.error('Erro ao salvar plantas');
        setLoading(false);
      });
  };

  useEffect((): void => {
    getInitialData();
  }, []);

  return (
    <PageWrapper>
      <PageTitle
        Text="Plantas por usuário"
      />
      {
        (loading) ? (<Loading />) : (
          <PlantasAcessoForm
            initialValues={plantas}
            onSubmit={onSubmit}
            onCancel={onCancel}
            submitting={false}
            empresaGuid={Empresa!.Guid!}
          />
        )
      }
    </PageWrapper>
  );
};

PlantasAcessos.displayName = 'PlantasAcessos';

export default PlantasAcessos;
