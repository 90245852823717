import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PageWrapper from '../../../PageWrapper/PageWrapper';
import PageTitle from '../../../PageTitle/PageTitle';
import { Box } from 'grommet';
import AddVideoForm from '../../../Forms/AddVideo/AddVideoForm';
import adicionarVideoAoProjetoInitialValues from '../../Helpers/adicionarVideoAoProjetoInitialValues';
import adicionarVideoAoProjetoSubmit from '../../Helpers/adicionarVideoAoProjetoSubmit';

export interface AdicionarVideoAoProjetoProps {}

const AdicionarVideoAoProjeto: React.FC<AdicionarVideoAoProjetoProps> = ({}): JSX.Element => {
  const navigate = useNavigate();
  const { guid } = useParams<{ guid: string }>();
  const onCancel = (): void => navigate(`/auth/manageProjetosVideos/${guid}`);

  return (
    <PageWrapper>
      <PageTitle
        Text="Adicionar Vídeo ao Projeto"
      />
      <Box
        width="large"
      >
        <AddVideoForm
          initialValues={adicionarVideoAoProjetoInitialValues(guid!)}
          onSubmit={adicionarVideoAoProjetoSubmit}
          onCancel={onCancel}
        />
      </Box>
    </PageWrapper>
  );
};

AdicionarVideoAoProjeto.displayName = 'AdicionarVideoAoProjeto';

export default AdicionarVideoAoProjeto;
