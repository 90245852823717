import { IEmpresa } from '../../../../Interfaces/IEmpresa';

const adicionarEmpresaInitialValues: IEmpresa = {
  Guid: '',
  CNPJ: '',
  RazaoSocial: '',
  NomeFantasia: '',
  Endereco: '',
  ResponsavelTecnico: '',
  Telefone: '',
  Email: '',
  Ativo: true,
  Tipo: 2,
};

export default adicionarEmpresaInitialValues;
