import React, { useState } from 'react';
import { IDataTableSort } from '../../../../Interfaces/IDataTableSort';
import { ColumnConfig, DataTable, Text } from 'grommet';
import { IEmpresa } from '../../../../Interfaces/IEmpresa';
import PlantasCompartilhadasLista from './PlantasCompartilhadasLista';

export interface PlantasCompartilhadasDataTableProps {
  empresas: IEmpresa[];
}

const PlantasCompartilhadasDataTable: React.FC<PlantasCompartilhadasDataTableProps> = ({ empresas }): JSX.Element => {
  const [sort, setSort] = useState<IDataTableSort>({
    property: 'NomeFantasia',
    direction: 'desc',
  });

  const columns: ColumnConfig<IEmpresa>[] = [
    {
      property: 'NomeFantasia',
      primary: false,
      search: true,
      sortable: true,
      header: 'Empresa',
      render: (datum: IEmpresa): React.ReactNode => (
        <Text>{datum.NomeFantasia}</Text>
      ),
    },
    {
      property: 'Plantas',
      primary: false,
      search: false,
      sortable: false,
      header: 'Plantas',
      render: (datum: IEmpresa): React.ReactNode => (
        <PlantasCompartilhadasLista
          plantas={datum.Plantas!}
        />
      ),
    },
  ];
  
  return (
    <DataTable
      fill="horizontal"
      data={empresas}
      border={{
        body: 'bottom',
      }}
      sortable={false}
      sort={sort}
      onSort={setSort}
      pad={{
        horizontal: 'small',
        vertical: 'small',
      }}
      rowProps={{
        true: {
          background: 'dark-brand',
        },
      }}
      columns={columns}
    />
  );
};

PlantasCompartilhadasDataTable.displayName = 'PlantasCompartilhadasDataTable';

export default PlantasCompartilhadasDataTable;
