import { IAppContextReducerAction } from '../../../../Interfaces/IAppContextReducerAction';
import { IConsentCookie } from '../../../../Interfaces/IConsentCookie';
import { IEmpresa } from '../../../../Interfaces/IEmpresa';
import { IUser } from '../../../../Interfaces/IUser';

const appContextReducer = (state: IUser, action: IAppContextReducerAction): IUser => {
  switch (action.type) {
    case 'changeAuth':
      return {
        ...state,
        IsAuthenticated: !!action.value,
      };
    case 'changeLanguage':
      return {
        ...state,
        Language: action.value?.toString(),
      };
    case 'changeTheme':
      return {
        ...state,
        Theme: action.value?.toString(),
      };
    case 'changeConsent':
      return {
        ...state,
        ConsentCookie: action.value as IConsentCookie,
      };
    case 'changeFirstName':
      return {
        ...state,
        FirstName: action.value?.toString(),
      };
    case 'changeLastName':
      return {
        ...state,
        LastName: action.value?.toString(),
      };
    case 'changeNickName':
      return {
        ...state,
        NickName: action.value?.toString(),
      };
    case 'changeEmail':
      return {
        ...state,
        Email: action.value?.toString(),
      };
    case 'changeDrawer':
      return {
        ...state,
        Drawer: !!action.value,
      };
    case 'changeConfig':
      return {
        ...state,
        Config: !!action.value,
      };
    case 'changeValidated':
      return {
        ...state,
        Validated: !!action.value,
      };
    case 'changeOwner':
      return {
        ...state,
        Owner: !!action.value,
      };
    case 'changeAdmin':
      return {
        ...state,
        Admin: !!action.value,
      };
    case 'changeTechnician':
      return {
        ...state,
        Technician: !!action.value,
      };
    case 'changeEmpresa':
      return {
        ...state,
        Empresa: action.value as IEmpresa,
      };
    default:
      return state;
  }
};

export default appContextReducer;
