import { Route } from 'react-router-dom';
import IsAdminAuth from './IsAdminAuth';
import Error404 from '../../Error404/Error404';
import ManageProjetos from '../../ManageProjetos/ManageProjetos';
import AdicionarProjeto from '../../ManageProjetos/Adicionar/AdicionarProjeto';
import ManageProjetosDocumentos from '../../ManageProjetosDocumentos/ManageProjetosDocumentos';
import AdicionarDocumentoDoProjeto from '../../ManageProjetosDocumentos/Adicionar/AdicionarDocumentoDoProjeto';
import ManageProjetosRelatorio from '../../ManageProjetosRelatorio/ManageProjetosRelatorio';
import EditarProjeto from '../../ManageProjetos/Editar/EditarProjeto';
import EquipamentosPorEmpresa from '../../ManageEquipamentos/Components/EquipamentosPorEmpresa/EquipamentosPorEmpresa';
import AdicionarEquipamento from '../../ManageEquipamentos/Components/AdicionarEquipamento/AdicionarEquipamento';
import EditarEquipamento from '../../ManageEquipamentos/Components/EditarEquipamento/EditarEquipamento';
import ManageEquipamentosDocumentos from '../../ManageEquipamentosDocumentos/ManageEquipamentosDocumentos';
import AdicionarEquipamentoDocumento from '../../ManageEquipamentosDocumentos/Components/AdicionarDocumento/AdicionarEquipamentoDocumento';
import ManageProjetoEquipamentos from '../../ManageProjetoEquipamentos/ManageProjetoEquipamentos';
import AdicionarProjetoEquipamento from '../../ManageProjetoEquipamentos/Components/Adicionar/AdicionarProjetoEquipamento';
import ManageProjetoVideos from '../../ManageProjetoVideos/ManageProjetoVideos';
import AdicionarVideoAoProjeto from '../../ManageProjetoVideos/Components/Adicionar/AdicionarVideoAoProjeto';
import VideoUpload from '../../ManageVideos/Components/VideoUpload/VideoUpload';
import EditarVideoDoProjeto from '../../ManageProjetoVideos/Components/Editar/EditarVideoDoProjeto';
import EditarEquipamentoDocumento from '../../ManageEquipamentosDocumentos/Components/EditarDocumento/EditarEquipamentoDocumento';
import MeusDocumentos from '../../ManageEmpresasDocumentos/Components/MeusDocumentos/MeusDocumentos';
import AdicionarMeuDocumento from '../../ManageEmpresasDocumentos/Components/AdicionarMeuDocumento/AdicionarMeuDocumento';
import EditarMeuDocumento from '../../ManageEmpresasDocumentos/Components/EditarMeuDocumento/EditarMeuDocumento';
import ManageProjetoTecnicos from '../../ManageProjetoTecnicos/ManageProjetoTecnicos';
import EditUser from '../../ManageUsers/Components/EditUser/EditUser';
import EditarUsuariosDocumentos from '../../ManageUsuariosDocumentos/Components/EditarUsuariosDocumentos/EditarUsuariosDocumentos';
import InserirUsuario from '../../MinhaConta/MinhaEmpresa/Components/InserirUsuario';
import UsuariosDaMinhaEmpresa from '../../MinhaConta/Usuários/UsuariosDaMinhaEmpresa';
import Vencimentos from '../../Vencimentos/Vencimentos';
import MinhasPlantas from '../../MinhaConta/Plantas/MinhasPlantas';
import EditarPlanta from '../../ManagePlantas/Components/EditarPlanta/EditarPlanta';
import AdicionarPlantaNaMinhaEmpresa from '../../MinhaConta/Plantas/Components/AdicionarPlantaNaMinhaEmpresa';
import PlantasAcessos from '../../MinhaConta/PlantasAcessos/PlantasAcessos';
import EmpresasPrestadoras from '../../EmpresasPrestadoras/EmpresasPrestadoras';
import AdicionarEmpresaPrestadora from '../../EmpresasPrestadoras/Components/Adicionar/AdicionarEmpresaPrestadora';
import ManagePlantasCompartilhamentos from '../../ManagePlantasCompartilhamentos/ManagePlantasCompartilhamentos';
import AdicionarCompartilhamento from '../../ManagePlantasCompartilhamentos/Components/Adicionar/AdicionarCompartilhamento';
import PlantasCompartilhadasComMinhaEmpresa from '../../PlantasCompartilhadasComMinhaEmpresa/PlantasCompartilhadasComMinhaEmpresa';
import ProjetosDaPlanta from '../../ManagePlantas/Components/ProjetosDaPlanta/ProjetosDaPlanta';


const useIsAdminRouter = (): JSX.Element => {
  return (
    <>
      <Route path="admin" element={<IsAdminAuth />} >
        {/* Projetos */}
        <Route path="projetos" element={<ManageProjetos />} />
        <Route path="projetosAdicionar" element={<AdicionarProjeto />} />
        <Route path="relatorioProjeto/:guid" element={<ManageProjetosRelatorio />} />
        <Route path="editarProjeto/:guid" element={<EditarProjeto />} />
        {/* /Projetos */}

        {/* Manage Projeto Documentos */}
        <Route path="manageProjetosDocumentos/:guid" element={<ManageProjetosDocumentos />} />
        <Route path="adicionarDocumentoProjeto/:guid" element={<AdicionarDocumentoDoProjeto />} />
        {/* /Manage Projeto Documentos */}

        {/* Manage Projeto Equipamentos */}
        <Route path="manageProjetosEquipamentos/:guid" element={<ManageProjetoEquipamentos />} />
        <Route path="adicionarProjetosEquipamento/:guid" element={<AdicionarProjetoEquipamento />} />
        {/* /Manage Projeto Equipamentos */}

        {/* Manage Projeto Vídeos */}
        <Route path="manageProjetosVideos/:guid" element={<ManageProjetoVideos />} />
        <Route path="adicionarProjetosVideo/:guid" element={<AdicionarVideoAoProjeto />} />
        <Route path="editarProjetoVideo/:videoGuid/:projetoGuid" element={<EditarVideoDoProjeto />} />
        <Route path="videoUpload/:guid" element={<VideoUpload />} />
        {/* /Manage Projeto Vídeos */}

        {/* Manage Projeto Técnicos */}
        <Route path="manageProjetosTecnicos/:guid" element={<ManageProjetoTecnicos />} />
        {/* Manage Projeto Técnicos */}

        {/* Manage Equipamentos */}
        <Route path="manageEquipamentos" element={<EquipamentosPorEmpresa />} />
        <Route path="adicionarEquipamento" element={<AdicionarEquipamento />} />
        <Route path="editarEquipamento/:guid" element={<EditarEquipamento />} />
        {/* /Manage Equipamentos */}

        {/* Manage Equipamento Documentos */}
        <Route path="manageEquipamentosDocumentos/:guid" element={<ManageEquipamentosDocumentos />} />
        <Route path="adicionarDocumentoEquipamento/:guid" element={<AdicionarEquipamentoDocumento />} />
        <Route path="editarEquipamentoDocumento/:guid" element={<EditarEquipamentoDocumento />} />
        {/* /Manage Equipamento Documentos */}

        {/* Manage Empresa Documentos */}
        <Route path="meusDocumentos" element={<MeusDocumentos />} />
        <Route path="adicionarDocumento" element={<AdicionarMeuDocumento />} />
        <Route path="editarDocumento/:guid" element={<EditarMeuDocumento />} />
        {/* /Manage Empresa Documentos */}

        { /* Usuários */}
        <Route path="editUser/:guid" element={<EditUser />} />
        <Route path="usuarios" element={<UsuariosDaMinhaEmpresa />} />
        <Route path="inserirUsuario" element={<InserirUsuario />} />
        { /* /Usuários */}

        {/* Manage Usuario Documentos */}
        <Route path="EditarUsuariosDocumentos/:guid" element={<EditarUsuariosDocumentos />} />
        {/* /Manage Usuario Documentos */}

        {/* Plantas */}
        <Route path="plantas" element={<MinhasPlantas />} />
        <Route path="editarPlanta/:guid" element={<EditarPlanta />} />
        <Route path="adicionarPlanta" element={<AdicionarPlantaNaMinhaEmpresa />} />
        <Route path="plantasAcessos/:guid" element={<PlantasAcessos />} />
        <Route path="plantasCompartilhadasComMinhaEmpresa" element={<PlantasCompartilhadasComMinhaEmpresa />} />
        <Route path="managePlantasProjetos/:guid" element={<ProjetosDaPlanta />} />
        {/* /Plantas */}

        {/* Manage Compartilhamento de Plantas */}
        <Route path="managePlantasCompartilhamentos/:guid" element={<ManagePlantasCompartilhamentos />} />
        <Route path="adicionarCompartilhamento/:guid" element={<AdicionarCompartilhamento />} />
        {/* /Manage Compartilhamento de Plantas */}

        {/* Empresas Prestadoras de Serviço */}
        <Route path="empresasPrestadoras" element={<EmpresasPrestadoras />} />
        <Route path="adicionarEmpresaPrestadora" element={<AdicionarEmpresaPrestadora />} />
        {/* /Empresas Prestadoras de Serviço */}

        <Route path="vencimentos" element={<Vencimentos />} />

        <Route path="*" element={<Error404 />} />
      </Route>
    </>
  );
};

export default useIsAdminRouter;
