import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box } from 'grommet';
import PageWrapper from '../../../PageWrapper/PageWrapper';
import PageTitle from '../../../PageTitle/PageTitle';
import EmpresaForm from '../../../Forms/Empresa/EmpresaForm';
import adicionarEmpresaPrestadoraInitialValues from '../../Helpers/adicionarEmpresaPrestadoraInitialValues';
import { IEmpresa } from '../../../../../Interfaces/IEmpresa';
import adicionarEmpresaPrestadoraSubmit from '../../Helpers/adicionarEmpresaPrestadoraSubmit';
import { toast } from 'react-toastify';

export interface AdicionarEmpresaPrestadoraProps {}

const AdicionarEmpresaPrestadora: React.FC<AdicionarEmpresaPrestadoraProps> = ({}): JSX.Element => {
  const navigate = useNavigate();
  const onCancel = (): void => {
    navigate('/admin/empresasPrestadoras');
  };

  const [submitting, setSubmitting] = useState<boolean>(false);

  const onSubmit = async (empresa: IEmpresa): Promise<void> => {
    setSubmitting(true);

    await adicionarEmpresaPrestadoraSubmit(empresa)
      .then((data): void => {
        if (data.Success) {
          toast.success('Empresa prestadora adicionada com sucesso');
          navigate('/admin/empresasPrestadoras');
        } else {
          toast.error('Erro ao adicionar a empresa prestadora');
          setSubmitting(false);
        }
      })
      .catch((): void => {
        toast.error('Erro ao adicionar a empresa prestadora');
        setSubmitting(false);
      });
  };

  return (
    <PageWrapper>
      <PageTitle Text="Adicionar Empresa Prestadora" />

      <Box
        width="large"
      >
        <EmpresaForm
          initialValues={adicionarEmpresaPrestadoraInitialValues()}
          onSubmit={onSubmit}
          onCancel={onCancel}
          submitting={submitting}
          editing
        />
      </Box>
    </PageWrapper>
  );
};

AdicionarEmpresaPrestadora.displayName = 'AdicionarEmpresaPrestadora';

export default AdicionarEmpresaPrestadora;
