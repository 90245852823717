import React, { useEffect, useState } from 'react';
import { Box } from 'grommet';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import MuxUploader from '@mux/mux-uploader-react';
import PageWrapper from '../../../PageWrapper/PageWrapper';
import PageTitle from '../../../PageTitle/PageTitle';
import getMuxUploadUrl from '../../Helpers/getMuxUploadUrl';
import Loading from '../../../Loading/Loading';

export interface VideoUploadProps {}

const VideoUpload: React.FC<VideoUploadProps> = ({}): JSX.Element => {
  const { guid } = useParams<{ guid: string }>();
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(true);
  const [uploadUrl, setUploadUrl] = useState<string>('');

  const getUploadUrl = async (): Promise<void> => {
    setLoading(true);

    await getMuxUploadUrl(guid!)
      .then((data): void => {
        if (data.Success) {
          setUploadUrl(data.Object!);
          setLoading(false);
        } else {
          toast.error('Erro ao buscar URL de upload');
        }
      })
      .catch((): void => {
        toast.error('Erro ao buscar URL de upload');
      });
  };

  useEffect((): void => {
    getUploadUrl();
  }, []);

  const onUploadStart = (): void => {
    toast.warn('Upload iniciado');
  };

  const onUploadError = (): void => {
    toast.error('Erro ao fazer upload');
  };

  const onSuccess = (): void => {
    toast.success('Upload feito com sucesso');
    navigate('/auth/projetos');
  };

  return (
    <PageWrapper>
      <PageTitle
        Text="Upload de vídeo"
      />
      <Box
        width="large"
      >
        {
          (loading) ? (<Loading />) : (
            <MuxUploader
              endpoint={uploadUrl}
              onUploadStart={onUploadStart}
              onUploadError={onUploadError}
              onSuccess={onSuccess}
            />
          )
        }
      </Box>
    </PageWrapper>
  );
};

VideoUpload.displayName = 'VideoUpload';

export default VideoUpload;
