import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button } from 'grommet';
import { IUser } from '../../../../Interfaces/IUser';
import useAppContext from '../../Template/Context/useAppContext';
import { Add, Attachment } from 'grommet-icons';

export interface ManageUsersTotalDocumentosProps {
  usuario: IUser;
}

const ManageUsersTotalDocumentos: React.FC<ManageUsersTotalDocumentosProps> = ({ usuario }): JSX.Element => {
  const {
    state: { Admin },
  } = useAppContext();

  const navigate = useNavigate();

  const onDocumentosClick = (): void => {
    const url: string = (Admin) ? `/auth/manageUsuariosDocumentos/${usuario.Guid}` : `/owner/manageUsuariosDocumentos/${usuario.Guid}`;

    navigate(url);
  };

  const onNavigateToAddDocumento = (): void => {
    if (Admin) {
      navigate(`/auth/adicionarDocumentoUsuario/${usuario.Guid}`);
    } else {
      navigate(`/owner/adicionarDocumentoUsuario/${usuario.Guid}`);
    }
  };

  return (
    <Box
    direction="row"
    gap="1rem"
    align="center"
  >
    <Button 
    size='xsmall'
    style={{ minWidth: '80px' }}
    secondary
    icon={<Attachment size="12px" />}
    title="Clique para ver os documentos da empresa"
      onClick={onDocumentosClick}
      label={usuario.TotalDocumentos || '0'}
    />
    <Button size='small' icon={<Add size='14px' />} hoverIndicator onClick={onNavigateToAddDocumento} />
  </Box>
  );
};

ManageUsersTotalDocumentos.displayName = 'ManageUsersTotalDocumentos';

export default ManageUsersTotalDocumentos;
