import React, { useEffect, useState } from 'react';
import PageWrapper from '../PageWrapper/PageWrapper';
import PageTitle from '../PageTitle/PageTitle';
import { Box, Paragraph } from 'grommet';
import { IEmpresa } from '../../../Interfaces/IEmpresa';
import getPlantasCompartilhadas from './Helpers/getPlantasCompartilhadas';
import { toast } from 'react-toastify';
import Loading from '../Loading/Loading';
import PlantasCompartilhadasDataTable from './Components/PlantasCompartilhadasDataTable';

export interface PlantasCompartilhadasComMinhaEmpresaProps {}

const PlantasCompartilhadasComMinhaEmpresa: React.FC<PlantasCompartilhadasComMinhaEmpresaProps> = ({}): JSX.Element => {
  const [empresas, setEmpresas] = useState<IEmpresa[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const getData = async (): Promise<void> => {
    setLoading(true);

    await getPlantasCompartilhadas()
      .then((data): void => {
        if (data.Success) {
          setEmpresas(data.Object!);
        } else {
          toast.error('Erro ao buscar as plantas compartilhadas com sua empresa.');
        }

        setLoading(false);
      })
      .catch((): void => {
        toast.error('Erro ao buscar as plantas compartilhadas com sua empresa.');
      });
  };

  useEffect((): void => {
    getData();
  }, []);

  return (
    <PageWrapper>
      <PageTitle
        Text="Plantas Compartilhadas com Minha Empresa"
      />
      <Box alignSelf="start" align="start" width="60%">
        <Paragraph>
          Nesta página é possível conferir todas as plantas que os tomadores
          de serviço compartilharam com minha empresa. As plantas listadas
          nesta página estarão disponíveis na criação de novos projetos.
        </Paragraph>
      </Box>
      {
        (loading) ? (<Loading />) : (
          <PlantasCompartilhadasDataTable
            empresas={empresas}
          />
        )
      }
    </PageWrapper>
  );
};

PlantasCompartilhadasComMinhaEmpresa.displayName = 'PlantasCompartilhadasComMinhaEmpresa';

export default PlantasCompartilhadasComMinhaEmpresa;
