import React from 'react';
import { IPlanta } from '../../../../Interfaces/IPlanta';
import { Box, Text } from 'grommet';

export interface DropDownPlantasOptionProps {
  planta: IPlanta;
}

const DropDownPlantasOption: React.FC<DropDownPlantasOptionProps> = ({ planta }): JSX.Element => {
  return (
    <Box
      direction="row"
      gap="xsmall"
    >
      <Text>{planta.Nome}</Text>
    </Box>
  );
};

DropDownPlantasOption.displayName = 'DropDownPlantasOption';

export default DropDownPlantasOption;
