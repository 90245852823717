import React from 'react';
import { Box, Button } from 'grommet';
import { useNavigate } from 'react-router-dom';
import { Edit } from 'grommet-icons';
import PageWrapper from '../../PageWrapper/PageWrapper';
import PageTitle from '../../PageTitle/PageTitle';
import AdminChecker from '../../AdminChecker/AdminChecker';
import useAppContext from '../../Template/Context/useAppContext';
import DashboardEmpresa from './Components/DashboardEmpresa/DashboardEmpresa';
import DashboardOwner from './Components/DashboardOwner/DashboardOwner';

export interface MinhaEmpresaProps {}

const MinhaEmpresa: React.FC<MinhaEmpresaProps> = ({}): JSX.Element => {
  const navigate = useNavigate();

  const {
    state: { Owner },
  } = useAppContext();

  const editarMinhaEmpresa = (): void => {
    navigate('/auth/editarMinhaEmpresa');
  };

  return (
    <PageWrapper>
      <Box width="100%" justify="between" align="center" direction="row">
        <PageTitle Text="Administração" />
        {
          (!Owner) && (
            <AdminChecker>
              <Button
                secondary
                label="Editar"
                title="Editar empresa"
                onClick={editarMinhaEmpresa}
                icon={<Edit size="16px" />}
              />
            </AdminChecker>
          )
        }
      </Box>

      {
        (Owner) ? (<DashboardOwner />) : (<DashboardEmpresa />)
      }
    </PageWrapper>
  );
};

MinhaEmpresa.displayName = 'MinhaEmpresa';

export default MinhaEmpresa;
