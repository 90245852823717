import React from 'react';
import { Anchor, Text } from 'grommet';
import { useNavigate } from 'react-router-dom';
import { IEmpresa } from '../../../../Interfaces/IEmpresa';
import useAppContext from '../../Template/Context/useAppContext';

export interface ManageEmpresasTotalPlantasProps {
  empresa: IEmpresa;
}

const ManageEmpresasTotalPlantas: React.FC<ManageEmpresasTotalPlantasProps> = ({ empresa }): JSX.Element => {
  const navigate = useNavigate();

  const {
    state: { Owner },
  } = useAppContext();

  const onPlantasClick = (): void => {
    navigate(`/owner/managePlantas/${empresa.Guid}`);
  };

  return (Owner) ? (
    <Text>{empresa.TotalPlantas || 0}</Text>
  ) : (
    <Anchor
      title="Clique para ver as plantas"
      onClick={onPlantasClick}
    >
      {empresa.TotalPlantas || 0}
    </Anchor>
  );
};

ManageEmpresasTotalPlantas.displayName = 'ManageEmpresasTotalPlantas';

export default ManageEmpresasTotalPlantas;
