import { Box } from 'grommet';
import React from 'react';
import StatsBox from '../../../../Dashboard/Components/StatsBox/StatsBox';

export interface DashboardOwnerProps {}

const DashboardOwner: React.FC<DashboardOwnerProps> = ({}): JSX.Element => {
  return (
    <Box margin={{
      top: '4rem',
    }} fill="horizontal">
      <StatsBox />
    </Box>
  );
};

DashboardOwner.displayName = 'DashboardOwner';

export default DashboardOwner;
