import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppsRounded, Group, Schedule, Tree, VmMaintenance } from 'grommet-icons';
import { Box } from 'grommet';
import { SideBarCustomizedButton } from '../SideBarNavStyles';
import AdminChecker from '../../../../../AdminChecker/AdminChecker';

export interface SidebarLinksTomadorProps {}

const SidebarLinksTomador: React.FC<SidebarLinksTomadorProps> = ({}): JSX.Element => {
  const navigate = useNavigate();
  const location = useLocation();

  const checkifRouteIsActive = (route: string): string => {
    if (location.pathname === route) {
      return 'active';
    }
    return '';
  };

  // /* Administração */
  const onMinhaEmpresaClick = (): void => {
    navigate('/auth/minhaEmpresa');
  };

  const onUsuariosDaMinhaEmpresaClick = (): void => {
    navigate('/admin/usuarios');
  };

  const onVencimentosClick = (): void => {
    navigate('/admin/vencimentos');
  };

  const onPlantasClick = (): void => {
    navigate('/admin/plantas');
  };
  // /* /Administração */

  const onPrestadoresClick = (): void => {
    navigate('/admin/empresasPrestadoras');
  };

  return (
    <Box
      gap="6px"
      margin={{
        bottom: '32px',
      }}
      width="100%"
    >
      <SideBarCustomizedButton
        primary
        label="Administração"
        icon={<AppsRounded size="14px" />}
        onClick={onMinhaEmpresaClick}
        className={checkifRouteIsActive('/auth/minhaEmpresa')}
      />
      <AdminChecker>
        <SideBarCustomizedButton
          primary
          label="Usuários"
          icon={<Group size="14px" />}
          onClick={onUsuariosDaMinhaEmpresaClick}
          className={checkifRouteIsActive('/admin/usuarios')}
        />
      </AdminChecker>
      <AdminChecker>
        <SideBarCustomizedButton
          primary
          label="Plantas"
          icon={<Tree size="14px" />}
          onClick={onPlantasClick}
          className={checkifRouteIsActive('/admin/plantas')}
        />
      </AdminChecker>
      <AdminChecker>
        <SideBarCustomizedButton
          primary
          label="Vencimentos"
          icon={<Schedule size="14px" />}
          onClick={onVencimentosClick}
          className={checkifRouteIsActive('/admin/vencimentos')}
        />
      </AdminChecker>
      <AdminChecker>
        <SideBarCustomizedButton
          primary
          label="Prestadores"
          icon={<VmMaintenance size="14px" />}
          onClick={onPrestadoresClick}
          className={checkifRouteIsActive('/admin/empresasPrestadoras')}
        />
      </AdminChecker>
    </Box>
  );
};

SidebarLinksTomador.displayName = 'SidebarLinksTomador';

export default SidebarLinksTomador;
