import { Box, Button, FormExtendedEvent, FormField, MaskedInput, TextInput } from 'grommet';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { IUser } from '../../../../Interfaces/IUser';
import { IBasicReturn } from '../../../../Interfaces/IBasicReturn';
import FormWrapper from '../../FormWrapper/FormWrapper';
import { onEmailValidationRegExp } from '../../../../utils/onEmailRegexValidation';
import passwordRecoveryValidateCode from '../../PasswordRecovery/Helpers/passwordRecoveryValidateCode';
import { useNavigate } from 'react-router-dom';

export interface RecuperarSenhaFormProps {
  initialValues: IUser;
  sendCode: (email: string) => Promise<IBasicReturn>;
  onSubmit: (values: IUser) => Promise<IBasicReturn>;
}

const RecuperarSenhaForm: React.FC<RecuperarSenhaFormProps> = ({ initialValues, sendCode, onSubmit }): JSX.Element => {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [values, setValues] = useState<IUser>(initialValues);
  const onChange = (nextValues: IUser): void => setValues(nextValues);

  const navigate = useNavigate();

  const [step, setStep] = useState<number>(1);

  const onClear = (): void => {
    setValues(initialValues);
  };
  
  const onCancel = (): void => {
    navigate('/login');
  };

  const handleSubmit = async (evt: FormExtendedEvent<IUser>): Promise<void> => {
    const { value } = evt;

    setSubmitting(true);

    if (step === 1) {
      await sendCode(value.Email!)
        .then((data): void => {
          if (data.Success) {
            toast.success('Por favor informe o código enviado para o email');
            setStep(2);
          } else {
            toast.error('Email não encontrado');
          }
          setSubmitting(false);
        })
        .catch((): void => {
          toast.error('Erro ao verificar email');
          setSubmitting(false);
        });
      return;
    }

    if (step === 2) {
      await passwordRecoveryValidateCode(value)
        .then((data): void => {
          if (data.Success) {
            toast.success('Código validado com sucesso');
            setValues({ ...values, Guid: data.Object! });
            setStep(3);
          } else {
            toast.error('Código inválido');
          }
          setSubmitting(false);
        })
        .catch((): void => {
          toast.error('Erro ao validar código');
          setSubmitting(false);
        });
      return;
    }

    await onSubmit(value)
      .then((data): void => {
        if (data.Success) {
          onClear();
          toast.success('Nova senha salva com sucesso');
          navigate('/login');
        } else {
          toast.error('Erro ao definir nova senha');
        }
        setSubmitting(false);
      })
      .catch((): void => {
        toast.error('Erro ao definir nova senha');
        setSubmitting(false);
      });
  };

  const validatePassword = (value: string): string | undefined => {
    if (value.length < 6) {
      return 'A senha deve ter no mínimo 6 caracteres';
    }
    if (value.length > 15) {
      return 'A senha deve ter no máximo 15 caracteres';
    }
  };

  const validateConfirmPassword = (value: string): string | undefined => {
    if (value !== values.Password) {
      return 'As senhas não conferem';
    }
  };

  return (
    <FormWrapper
      values={values}
      onChange={onChange}
      onSubmit={handleSubmit}
    >
      <FormField
        label="Email"
        name="Email"
        width="100%"
        validate={onEmailValidationRegExp('Email inválido')}
        readOnly={(step > 1)}
        required
      >
        <TextInput
          name="Email"
          type="email"
          aria-label="Por favor informe seu email"
          title="Por favor informe seu email"
          placeholder="mail@mail.com"
          disabled={(step > 1)}
        />
      </FormField>

      {
        (step > 1) && (
          <FormField
            label="Código de verificação"
            name="VerificationCode"
            width="100%"
            readOnly={(step > 2)}
            required
          >
            <MaskedInput
              mask={[
                {
                  regexp: /^([0-9]{1,3})$/,
                  placeholder: '000',
                  length: 3,
                },
                { fixed: '-' },
                {
                  regexp: /^([0-9]{1,3})$/,
                  length: 3,
                  placeholder: '000',
                },
              ]}
              name="VerificationCode"
              disabled={(step > 2)}
            />
          </FormField>
        )
      }

      {
        (step > 2) && (
          <>
            <FormField
              label="Nova senha"
              name="Password"
              width="100%"
              validate={validatePassword}
              required
            >
              <TextInput
                name="Password"
                type="password"
                aria-label="Por favor informe sua nova senha"
                title="Por favor informe sua nova senha"
                placeholder="********"
              />
            </FormField>

            <FormField
              label="Confirme a nova senha"
              name="ConfirmPassword"
              width="100%"
              validate={validateConfirmPassword}
              required
            >
              <TextInput
                name="ConfirmPassword"
                type="password"
                aria-label="Por favor confirme sua nova senha"
                title="Por favor confirme sua nova senha"
                placeholder="********"
              />
            </FormField>
          </>
        )
      }

      <Box direction="row" gap="medium" justify="start" margin={{ top: 'medium', bottom: 'medium' }}>
        <Button
          type="submit"
          label="Salvar"
          title="Salvar"
          disabled={submitting}
          primary
        />
        <Button
          label="Cancelar"
          title="Cancelar"
          onClick={onCancel}
        />
      </Box>
    </FormWrapper>
  );
};

RecuperarSenhaForm.displayName = 'RecuperarSenhaForm';

export default RecuperarSenhaForm;
