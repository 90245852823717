import React, { useEffect, useState } from 'react';
import PageWrapper from '../PageWrapper/PageWrapper';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Box, Button, Paragraph } from 'grommet';
import { Add } from 'grommet-icons';
import PageTitle from '../PageTitle/PageTitle';
import { IEmpresa } from '../../../Interfaces/IEmpresa';
import getPlantasCompartilhamentos from './Helpers/getPlantasCompartilhamentos';
import Loading from '../Loading/Loading';
import { IPlanta } from '../../../Interfaces/IPlanta';
import getPlantaInfoByGuid from '../ManagePlantas/Helpers/editarPlantaInitialValues';
import PlantasCompartilhamentosDataTable from './Components/PlantasCompartilhamentosDataTable';

export interface ManagePlantasCompartilhamentosProps {}

const ManagePlantasCompartilhamentos: React.FC<ManagePlantasCompartilhamentosProps> = ({}): JSX.Element => {
  const { guid } = useParams<{ guid: string }>();
  const navigate = useNavigate();

  const onAddCompartilhamentoClick = (): void => {
    navigate(`/admin/adicionarCompartilhamento/${guid}`);
  };

  const [empresas, setEmpresas] = useState<IEmpresa[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [planta, setPlanta] = useState<IPlanta>({});

  const getInitialData = async (): Promise<void> => {
    setLoading(true);

    const allData = Promise.all([
      getPlantasCompartilhamentos(guid!),
      getPlantaInfoByGuid(guid!),
    ]);

    allData
      .then((values): void => {
        const [compartilhamentosData, plantaData] = values;
        if (compartilhamentosData.Success) {
          setEmpresas(compartilhamentosData.Object!);
        } else {
          toast.error('Erro ao buscar os compartilhamentos de plantas');
        }

        if (plantaData.Success) {
          setPlanta(plantaData.Object!);
        } else {
          toast.error('Erro ao buscar a planta');
        }

        setLoading(false);
      })
      .catch((): void => {
        toast.error('Erro ao buscar os compartilhamentos de plantas');
      });
  };

  useEffect((): void => {
    getInitialData();
  }, []);

  return (
    <PageWrapper>
      <Box width="100%" justify="between" align="center" direction="row">
        <PageTitle Text="Gerenciar Compartilhamentos" />
        <Button
          primary
          label="Adicionar"
          title="Adicionar Compartilhamento"
          onClick={onAddCompartilhamentoClick}
          icon={<Add size="16px" />}
        />
      </Box>
      <Box alignSelf="start" align="start" width="60%">
        <Paragraph>
          Esta página exibe os compartilhamentos de plantas. Aqui você pode
          adicionar ou remover compartilhamentos de plantas.
        </Paragraph>
        <Paragraph>
          <strong>Planta:</strong> {planta.Nome}<br />
          <strong>Observação:</strong> {planta.Observacao}
        </Paragraph>
      </Box>
      {
        (loading) ? (<Loading />) : (
          <PlantasCompartilhamentosDataTable
            empresas={empresas}
            refreshData={getInitialData}
          />
        )
      }
    </PageWrapper>
  );
};

ManagePlantasCompartilhamentos.displayName = 'ManagePlantasCompartilhamentos';

export default ManagePlantasCompartilhamentos;
