import { Box, Paragraph } from 'grommet';
import { Briefcase, Home, MailOption, Notes, Phone, Server, UserManager } from 'grommet-icons';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { IEmpresa } from '../../../../../../Interfaces/IEmpresa';
import getMinhaEmpresa from '../../Helpers/getMinhaEmpresa';
import Loading from '../../../../Loading/Loading';
import StatsBox from '../../../../Dashboard/Components/StatsBox/StatsBox';

export interface DashboardEmpresaProps {}

const DashboardEmpresa: React.FC<DashboardEmpresaProps> = ({}): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(true);
  const [empresa, setEmpresa] = useState<IEmpresa>({});

  const getEmpresa = async (): Promise<void> => {
    setLoading(true);

    await getMinhaEmpresa()
      .then((data): void => {
        if (data.Success) {
          setEmpresa(data.Object!);
          setLoading(false);
        } else {
          toast.error('Erro ao buscar empresa');
        }
      })
      .catch((): void => {
        toast.error('Erro ao buscar empresa');
        setLoading(false);
      });
  };

  useEffect((): void => {
    getEmpresa();
  }, []);

  return (loading) ? (<Loading />) : (
    <>
      <Box
        direction="column"
        fill="horizontal"
        align="center"
        justify="between"
        gap="32px"
      >
        <Box
          direction="row"
          fill="horizontal"
          align="center"
          justify="between"
        >
          <Box pad="small" gap='8px'>
            <Box direction="row" gap="1rem" align="center">
              <Notes color="#716F8A" size="14px" />
              <Paragraph margin="none" color="#716F8A" size="small">
                Razão Social
              </Paragraph>
            </Box>
            <Paragraph margin="none" color={{ light: '#122136', dark: '#fff' }}>{empresa.RazaoSocial}</Paragraph>
          </Box>
          <Box pad="small" gap='8px'>
            <Box direction="row" gap="1rem" align="center">
              <Briefcase color="#716F8A" size="14px" />
              <Paragraph margin="none" color="#716F8A" size="small">
                Nome Fantasia
              </Paragraph>
            </Box>
            <Paragraph margin="none" color={{ light: '#122136', dark: '#fff' }}>{empresa.NomeFantasia}</Paragraph>
          </Box>
          <Box pad="small" gap='8px'>
            <Box direction="row" gap="1rem" align="center">
              <Home color="#716F8A" size="14px" />
              <Paragraph margin="none" color="#716F8A" size="small">
                Endereço
              </Paragraph>
            </Box>
            <Paragraph margin="none" color={{ light: '#122136', dark: '#fff' }}>{empresa.Endereco}</Paragraph>
          </Box>
        </Box>
        <Box
          direction="row"
          fill="horizontal"
          align="center"
          justify="between"
        >
          <Box pad="small" gap='8px'>
            <Box direction="row" gap="1rem" align="center">
              <Server color="#716F8A" size="14px" />
              <Paragraph margin="none" color="#716F8A" size="small">
                CNPJ
              </Paragraph>
            </Box>
            <Paragraph margin="none" color={{ light: '#122136', dark: '#fff' }}>{empresa.CNPJ}</Paragraph>
          </Box>

          <Box pad="small" gap='8px'>
            <Box direction="row" gap="1rem" align="center">
              <MailOption color="#716F8A" size="14px" />
              <Paragraph margin="none" color="#716F8A" size="small">
                E-mail
              </Paragraph>
            </Box>
            <Paragraph margin="none" color={{ light: '#122136', dark: '#fff' }}>{empresa.Email}</Paragraph>
          </Box>
          <Box pad="small" gap='8px'>
            <Box direction="row" gap="1rem" align="center">
              <Phone color="#716F8A" size="14px" />
              <Paragraph margin="none" color="#716F8A" size="small">
                Telefone
              </Paragraph>
            </Box>
            <Paragraph margin="none" color={{ light: '#122136', dark: '#fff' }}>{empresa.Telefone}</Paragraph>
          </Box>
          <Box pad="small" gap='8px'>
            <Box direction="row" gap="1rem" align="center">
              <UserManager color="#716F8A" size="14px" />
              <Paragraph margin="none" color="#716F8A" size="small">
                Responsável
              </Paragraph>
            </Box>
            <Paragraph margin="none" color={{ light: '#122136', dark: '#fff' }}>
              {empresa.ResponsavelTecnico}
            </Paragraph>
          </Box>
        </Box>
      </Box>
      <Box margin={{
        top: '4rem',
      }} fill="horizontal">
        <StatsBox />
      </Box>
    </>
  );
};

DashboardEmpresa.displayName = 'DashboardEmpresa';

export default DashboardEmpresa;
