import React, { useState } from 'react';
import { ColumnConfig, DataTable, Text } from 'grommet';
import { IProjeto } from '../../../../../../Interfaces/IProjeto';
import { IDataTableSort } from '../../../../../../Interfaces/IDataTableSort';
import ManageProjetosDataPrevista from '../../../../ManageProjetos/Components/ManageProjetosDataPrevista';
import ProjetosDaPlantaActions from './ProjetosDaPlantaActions';

export interface ProjetosDaPlantaDataTableProps {
  projetos: IProjeto[];
}

const ProjetosDaPlantaDataTable: React.FC<ProjetosDaPlantaDataTableProps> = ({ projetos }): JSX.Element => {
  const [sort, setSort] = useState<IDataTableSort>({
    property: 'DiasRestantes',
    direction: 'asc',
  });

  const columns: ColumnConfig<IProjeto>[] = [
    {
      property: 'Planta.Guid',
      primary: false,
      search: false,
      sortable: false,
      header: 'Planta',
      render: (datum: IProjeto): React.ReactNode => (
        <Text>{datum.Planta!.Nome}</Text>
      ),
    },
    {
      property: 'Titulo',
      primary: true,
      search: true,
      sortable: false,
      header: 'Título',
      render: (datum: IProjeto): React.ReactNode => (
        <Text>{datum.Titulo}</Text>
      ),
    },
    {
      property: 'Status',
      primary: false,
      search: false,
      sortable: true,
      header: 'Status',
      render: (datum: IProjeto): React.ReactNode => (
        <>
          {
            (datum.Status === 1) && (
              <Text>Não iniciado</Text>
            )
          }
          {
            (datum.Status === 2) && (
              <Text>Iniciado</Text>
            )
          }
          {
            (datum.Status === 3) && (
              <Text>Finalizado</Text>
            )
          }
        </>
      ),
    },
    {
      property: 'DiasRestantes',
      primary: false,
      search: false,
      sortable: true,
      header: 'Início',
      render: (datum: IProjeto): React.ReactNode => (
        (datum.Status === 1)
          ? (<ManageProjetosDataPrevista data={datum.PrevisaoInicio!} />)
          : (<Text truncate>{datum.Inicio}</Text>)
      ),
    },
    {
      property: 'Termino',
      primary: false,
      search: false,
      sortable: true,
      header: 'Término',
      render: (datum: IProjeto): React.ReactNode => (
        (datum.Status !== 3)
          ? (<ManageProjetosDataPrevista data={datum.PrevisaoTermino!} />)
          : (<Text truncate>{datum.Termino}</Text>)
      ),
    },
    {
      property: 'Empresa.Guid',
      primary: false,
      search: true,
      sortable: true,
      header: 'Prestador',
      render: (datum: IProjeto): React.ReactNode => (
        <Text>{datum.Empresa!.NomeFantasia}</Text>
      ),
    },
    {
      property: 'Guid',
      primary: true,
      search: false,
      sortable: false,
      header: 'Ações',
      render: (datum: IProjeto): React.ReactNode => (
        <ProjetosDaPlantaActions
          projeto={datum}
        />
      ),
    },
  ];

  return (
    <DataTable
      fill="horizontal"
      data={projetos}
      background={{
        body: ['background', 'background-front'],
      }}
      border={{
        body: 'bottom',
      }}
      sortable={false}
      sort={sort}
      onSort={setSort}
      pad={{
        horizontal: 'small',
        vertical: 'xsmall',
      }}
      rowProps={{
        true: {
          background: 'dark-brand',
        },
      }}
      columns={columns}
    />
  );
};

ProjetosDaPlantaDataTable.displayName = 'ProjetosDaPlantaDataTable';

export default ProjetosDaPlantaDataTable;
