import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box } from 'grommet';
import PageWrapper from '../../PageWrapper/PageWrapper';
import PageTitle from '../../PageTitle/PageTitle';
import AlterarNomeForm from '../../Forms/AlterarNome/AlterarNomeForm';
import onChangeNameInitialValues from './Helpers/onChangeNameInitialValues';
import onChangeNameSubmit from './Helpers/onchangeNameSubmit';
import { IUser } from '../../../../Interfaces/IUser';
import useAppContext from '../../Template/Context/useAppContext';

export interface ChangeNameProps {}

const ChangeName: React.FC<ChangeNameProps> = ({}): JSX.Element => {
  const navigate = useNavigate();
  const onCancel = (): void => {
    navigate('/auth/minhaEmpresa');
  };

  const {
    state: { FirstName, LastName },
  } = useAppContext();

  const initialValues: IUser = onChangeNameInitialValues(FirstName || '', LastName || '');

  return (
    <PageWrapper>
      <PageTitle
        Text="Alterar Nome"
      />
      <Box
        width="medium"
      >
        <AlterarNomeForm
          initialValues={initialValues}
          onSubmit={onChangeNameSubmit}
          onCancel={onCancel}
        />
      </Box>
    </PageWrapper>
  );
};

ChangeName.displayName = 'ChangeName';

export default ChangeName;
