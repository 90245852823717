import { IBasicReturn } from '../../../../Interfaces/IBasicReturn';
import { IProjeto } from '../../../../Interfaces/IProjeto';
import MyAxiosInstance from '../../../../utils/AxiosInstance';

const getProjetosPorPlanta = async (plantaGuid: string): Promise<IBasicReturn<IProjeto[]>> => {
  let apiReturn: IBasicReturn<IProjeto[]> = {
    Success: true,
  };

  await MyAxiosInstance().get<IBasicReturn<IProjeto[]>>(`Empresa/ProjetosPorPlanta/${plantaGuid}`)
    .then((response): IBasicReturn<IProjeto[]> => {
      const { data } = response;

      apiReturn = data;

      return apiReturn;
    })
    .catch((): IBasicReturn<IProjeto[]> => {
      apiReturn.Success = false;

      return apiReturn;
    });

  return apiReturn;
};

export default getProjetosPorPlanta;
