import { IBasicReturn } from '../../../../Interfaces/IBasicReturn';
import MyAxiosInstance from '../../../../utils/AxiosInstance';

const plantaCompartilhamentoExcluir = async (compartilhamentoGuid: string): Promise<IBasicReturn> => {
  let apiReturn: IBasicReturn = {
    Success: true,
  };

  await MyAxiosInstance().get<IBasicReturn>(`Empresa/PlantaCompartilhamentoExcluir/${compartilhamentoGuid}`)
    .then((response): IBasicReturn => {
      const { data } = response;

      apiReturn = data;

      return apiReturn;
    })
    .catch((): IBasicReturn => {
      apiReturn.Success = false;

      return apiReturn;
    });

  return apiReturn;
};

export default plantaCompartilhamentoExcluir;
