import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { IStreamingVideo } from '../../../Interfaces/IStreamingVideo';
import PageWrapper from '../PageWrapper/PageWrapper';
import PageTitle from '../PageTitle/PageTitle';
import { Box, Button, Paragraph } from 'grommet';
import { Add, LinkPrevious } from 'grommet-icons';
import Loading from '../Loading/Loading';
import getVideosByProjeto from './Helpers/getVideosByProjeto';
import ManageVideosDataTable from '../ManageVideos/Components/ManageVideosDataTable';

export interface ManageProjetoVideosProps {}

const ManageProjetoVideos: React.FC<ManageProjetoVideosProps> = ({}): JSX.Element => {
  const navigate = useNavigate();
  const { guid } = useParams<{ guid: string }>();

  const onAddVideoClick = (): void => {
    navigate(`/auth/adicionarProjetosVideo/${guid}`);
  };

  const onBackToProjetos = (): void => {
    navigate('/auth/projetos');
  };

  const [videos, setVideos] = useState<IStreamingVideo[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const getData = async (): Promise<void> => {
    setLoading(true);

    await getVideosByProjeto(guid!)
      .then((data): void => {
        if (data.Success) {
          setVideos(data.Object!);
        } else {
          toast.error('Erro ao buscar vídeos do projeto');
        }

        setLoading(false);
      })
      .catch((): void => {
        toast.error('Erro ao buscar vídeos do projeto');
      });
  };

  useEffect((): void => {
    getData();
  }, []);

  return (
    <PageWrapper>
      <Box width="100%" justify="between" align="center" direction="row">
        <PageTitle Text="Gerenciar Vídeos do Projeto" />
        <Button
          primary
          label="Adicionar"
          title="Adicionar Vídeo"
          onClick={onAddVideoClick}
          icon={<Add size="16px" />}
        />
      </Box>
      <Box alignSelf="start" align="start" width="60%">
        <Paragraph>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vitae
          ante semper, faucibus urna quis, bibendum urna. Suspendisse consequat
          luctus dolor, ac vestibulum ex mollis egestas. Cras in sapien id diam
          facilisis hendrerit quis non orci. Duis vel sodales dui. Suspendisse
          potenti. Donec dignissim est aliquet risus fermentum consectetur. 
        </Paragraph>
      </Box>
      {
        (loading) ? (<Loading />) : (
          <Box
            fill="horizontal"
            direction="column"
            gap="none"
            margin={{
              top: 'medium',
              bottom: 'medium',
            }}
          >
            <ManageVideosDataTable
              videos={videos}
              refreshData={getData}
            />
          </Box>
        )
      }
      <Box
        width="100%"
        align="center"
        direction="row"
        gap="xsmall"
        justify="start"
      >
        <Button
          primary
          label="Voltar"
          title="Voltar"
          onClick={onBackToProjetos}
          icon={<LinkPrevious  size="16px"/>}
        />
      </Box>
    </PageWrapper>
  );
};

ManageProjetoVideos.displayName = 'ManageProjetoVideos';

export default ManageProjetoVideos;
